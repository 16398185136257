import i18next from "../../../../i18n";
import { DELIVERY_TYPE } from "../../../../types";

type PeriodDateProps = {
  matToBid: any;
  handleSupplyPeriod: any;
};

export default function PeriodDate({
  matToBid,
  handleSupplyPeriod,
}: PeriodDateProps) {
  return (
    <div className="col-span-2 md:col-span-1 grid grid-cols-3">
      <div className="col-span-1">
        <label
          htmlFor="spot"
          className="uppercase block text-xs font-medium text-gray-700"
        >
          {i18next.t("tenders.form.term")}
          <span className="text-sm text-red-600"> *</span>
        </label>
        <div className="flex items-center gap-4 mt-2">
          <div className="flex items-center gap-2">
            <input
              name={`tender-period-${matToBid.id}`}
              id={`spot-${matToBid.id}`}
              type="radio"
              value={DELIVERY_TYPE.SPOT}
              checked={matToBid.spot === DELIVERY_TYPE.SPOT}
              onChange={(e) =>
                handleSupplyPeriod(e, matToBid, DELIVERY_TYPE.SPOT)
              }
              className="cursor-pointer"
            />
            <label
              className="uppercase text-xs text-gray-700 font-medium"
              htmlFor={`spot-${matToBid.id}`}
            >
              {i18next.t("tenders.form.spot")}
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              name={`tender-period-${matToBid.id}`}
              id={`period-${matToBid.id}`}
              type="radio"
              value={DELIVERY_TYPE.PERIOD}
              checked={matToBid.spot === DELIVERY_TYPE.PERIOD}
              className="cursor-pointer"
              onChange={(e) =>
                handleSupplyPeriod(e, matToBid, DELIVERY_TYPE.SPOT)
              }
            />
            <label
              className="uppercase text-xs text-gray-700 font-medium"
              htmlFor={`period-${matToBid.id}`}
            >
              {i18next.t("tenders.form.period")}
            </label>
          </div>
        </div>
      </div>
      {matToBid.spot && (
        <div className="col-span-2 flex items-center gap-3 relative w-full">
          {matToBid.spot === DELIVERY_TYPE.SPOT ? (
            <div className="w-full">
              <label
                htmlFor="startSupplyDate"
                className="uppercase block text-xs font-medium text-gray-700"
              >
                {i18next.t("tenders.form.date")}
                <span className="text-sm text-red-600"> *</span>
              </label>
              <input
                className="mt-1 focus:ring-spectum-light focus:border-spectum-light block w-full shadow-sm sm:text-xs border-gray-300 rounded"
                id="startSupplyDate"
                name="startSupplyDate"
                type="date"
                value={matToBid.startSupplyDate}
                onChange={(e) => handleSupplyPeriod(e, matToBid, "start")}
                required
              />
            </div>
          ) : (
            <>
              <div className="w-full">
                <label
                  htmlFor="startSupplyDate"
                  className="uppercase block text-xs font-medium text-gray-700"
                >
                  {i18next.t("tenders.form.from")}
                  <span className="text-sm text-red-600"> *</span>
                </label>
                <input
                  className="mt-1 focus:ring-spectum-light focus:border-spectum-light block w-full shadow-sm sm:text-xs border-gray-300 rounded"
                  id="startSupplyDate"
                  name="startSupplyDate"
                  type="date"
                  value={matToBid.startSupplyDate}
                  onChange={(e) => handleSupplyPeriod(e, matToBid, "start")}
                  required
                />
              </div>
              <div className="w-full">
                <label
                  htmlFor="endSupplyDate"
                  className="uppercase block text-xs font-medium text-gray-700"
                >
                  {i18next.t("tenders.form.to")}
                  <span className="text-sm text-red-600"> *</span>
                </label>
                <input
                  className="mt-1 focus:ring-spectum-light focus:border-spectum-light block w-full shadow-sm sm:text-xs border-gray-300 rounded"
                  id="endSupplyDate"
                  name="endSupplyDate"
                  type="date"
                  min={matToBid.startSupplyDate}
                  disabled={!matToBid.startSupplyDate}
                  value={matToBid.endSupplyDate}
                  onChange={(e) => handleSupplyPeriod(e, matToBid, "end")}
                  required
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

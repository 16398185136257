import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
import { UserResponse } from "../../types";

const ContactInformationCard = ({
  user,
  isSample,
}: {
  user: UserResponse;
  isSample?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <div className="col-span-1 bg-white rounded shadow divide-y divide-gray-200 mt-4 mb-4 grid">
      <div className="w-full flex items-center justify-between p-6 space-x-6">
        <div className="flex-1 truncate">
          {isSample && (
            <p className="text-spectum uppercase truncate text-md font-semibold">
              {t("tenders.tenderSucceed.sample")}
            </p>
          )}
          <p className="my-1 text-gray-500 text-xs truncate uppercase">
            {t("tenders.tenderSucceed.contactData")}
          </p>
          <div>
            <h3 className="my-1 text-gray-900 text-xs font-medium uppercase">
              {user.company.name} - {user.fullName}
            </h3>
            <p className="text-gray-900 text-xs font-medium truncate uppercase">
              <span className="text-gray-500">{t("profile.tax")}:</span>{" "}
              {user.company.taxId}
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="w-0 flex-1 flex">
            <a
              href={`mailto:${user?.email}`}
              className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-xs text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
            >
              <EnvelopeIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
              <span className="ml-3">{user?.email}</span>
            </a>
          </div>
          <div className="-ml-px w-0 flex-1 flex">
            <a
              href={`tel:${user?.phone}`}
              className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-xs text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
            >
              <PhoneIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
              <span className="ml-3">{user?.phone}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInformationCard;

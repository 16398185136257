import { Fragment } from "react";
import { useTranslation } from "react-i18next";

type InputFieldProps = {
  label: string;
  id: string;
  name: string;
  type?: string;
  register: any;
  placeholder?: string;
  required?: boolean;
  errorMessage?: any;
  errorName?: any;
  isOptional?: boolean;
  onChange?: any;
  min?: any;
  defaultValue?: string | number;
};

export default function InputField({
  label,
  id,
  name,
  type = "text",
  placeholder,
  errorName,
  errorMessage,
  required,
  register,
  isOptional,
  onChange,
  min,
  defaultValue,
  ...props
}: InputFieldProps) {
  const { t } = useTranslation();
  return (
    <Fragment>
      <label
        htmlFor={name}
        className="uppercase block text-xs font-medium text-gray-700"
      >
        {label}{" "}
        {isOptional && (
          <span className="text-gray-400">
            ({t("newMaterialForm.optional")})
          </span>
        )}
        {required ? (
          <span className="text-sm text-red-600">*</span>
        ) : (
          <span className="text-sm text-red-600"></span>
        )}
      </label>
      <input
        id={id}
        name={name}
        min={min}
        type={type}
        defaultValue={defaultValue}
        placeholder={placeholder}
        {...register(name, { required: required, onChange: onChange })}
        {...props}
        className="mt-1 focus:ring-spectum-light focus:border-spectum-light block w-full shadow-sm sm:text-xs border-gray-300 rounded"
      />
      {errorName && (
        <span className="text-xs text-red-500">{errorMessage}</span>
      )}
    </Fragment>
  );
}

import { Pill } from "../..";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { formatDate } from "../../../utils";
import { DeliveryInterface } from "../../../types";
import i18next from "../../../i18n";

const DeliveriesTableRow = ({ delivery }: { delivery: DeliveryInterface }) => {
  return (
    <tr key={delivery.id}>
      <td className="px-2 py-2 whitespace-nowrap text-xxs text-gray-900 uppercase font-bold">
        {delivery?.orderName}
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-xxs text-gray-900 uppercase">
        {delivery?.purchaseOrderNumber}
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-xxs text-gray-900">
        {delivery?.email}
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xxs text-gray-500">
          {formatDate(delivery.deliveryDate)}
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xxs text-gray-500">{delivery.deliveryTime}</div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <a
          href={delivery.purchaseOrderFile}
          target="_blank"
          rel="noreferrer"
          className="inline-flex items-center p-1 px-3 border border-transparent shadow-sm text-xs leading-4 font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
        >
          <ArrowDownTrayIcon
            className="-ml-0.5 mr-2 h-4 w-4"
            aria-hidden="true"
          />
          {i18next.t("cta.download")}
        </a>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <Pill delivery={delivery} />
      </td>
    </tr>
  );
};

export default DeliveriesTableRow;

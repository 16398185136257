import { CompanyResponse } from "./company.type";
import { ContentPaginatedInterface } from "./content.type";
import { ORDER_TYPES } from "./enums";
import { FileResponse } from "./file.type";
import { MaterialResponse, MaterialType } from "./material.type";
import { SiteResponse } from "./site.type";
import { UserResponse } from "./user.type";

export enum OrderState {
  SUCCEED = "SUCCEED",
  CLOSED = "CLOSED",
  OPEN = "OPEN",
  PENDING = "PENDING",
  CANCELLED = "CANCELLED",
  DRAFT = "DRAFT",
  REJECTED = "REJECTED",
  DELIVERED = "DELIVERED",
}

export interface MultipleOrderResponse {
  createdAt: string;
  updatedAt: string;
  id: string;
  name: string;
}

export interface OrderResponse {
  active: boolean;
  hasOfferSelected: boolean;
  address: string | null;
  application: string | null;
  comment: string | null;
  company: CompanyResponse;
  createdAt: string;
  currency: string;
  deleted: boolean;
  deliveryPlace: SiteResponse | null;
  details: string | null;
  email: string;
  endSupplyDate: string;
  files: FileResponse[]; // Assuming files are an array of URLs or file paths
  finishDate: string;
  fileUrl: string;
  fullname: string;
  id: string;
  initialPrice?: number;
  internalCode: string | null;
  material: MaterialResponse;
  measurementUnit: string;
  minimumDecrement: number;
  multipleOrder: MultipleOrderResponse | null;
  name: string;
  paymentMethod: string;
  paymentTerm: number;
  plicationUrl: string;
  purpose: string | null;
  quantity: number;
  spot: boolean;
  startDate: string;
  startSupplyDate: string;
  state: OrderState;
  suppliers: UserResponse[];
  tel: string | null;
  type: ORDER_TYPES;
  updatedAt: string;
  urgent: boolean | null;
  user: UserResponse;
}

export interface OrdersTuSumbitRequest {
  id?: string;
  orderId?: string;
  multipleOrderId?: string;
  ean?: string;
  quantity?: string | number;
  material?: MaterialResponse;
  user?: UserResponse;
  initialPrice?: string;
  minimumDecrement?: string;
  company?:
    | {
        id?: string;
      }
    | string;
  email?: string;
  coin?: string;
  globalMaterialName?: string;
  deliveryFormat?: string;
  owner?: string;
  fullName?: string;
  files?: FileResponse[];
  plicationUrl?: string;
  deliveryPlace?: SiteResponse | null;
  fullname?: string;
  name?: string;
  application?: string;
  purpose?: string;
  fileUrl?: string;
  measurementUnit?: string;
  incoTerm?: string;
  currency?: string;
  spot?: boolean | string;
  startSupplyDate?: Date;
  endSupplyDate?: Date;
  internalCode?: string;
  type?: ORDER_TYPES | MaterialType;
  state?: OrderState;
  suppliers: {
    materialId: string;
    emailsList: string;
  }[];
}

export interface AwardOrderRequest {
  offer: {
    createdAt: string;
    updatedAt: string;
    id: string;
    price: number;
    oldPrice: number;
    oldQuantity: number;
    quantity: number;
    deliveryDate: string;
    deliveryFormat: string;
    comment: string;
    fileURL: string;
    paymentMethod: string;
    order: OrderResponse;
    user: UserResponse;
    multipleOffer?: MultipleOrderResponse;
    selected: boolean;
    active: boolean;
  };
  replace: boolean;
}

export interface JointOrderResponse {
  [key: string]: OrderResponse[];
}

export type SimpleOrderPaginatedResponse =
  ContentPaginatedInterface<OrderResponse>;

import { Fragment, useState } from "react";
import {
  ArrowDownTrayIcon,
  CheckCircleIcon,
  CheckBadgeIcon,
  XCircleIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/solid";
import i18next from "../../i18n";
import { NO_SPEC, IT_DOESNT_HAVE_SPEC, SAMPLE_STATE } from "../../utils";
import { ContactInformationCard, Slide } from "..";
import Delegate from "../Material/Delegate/Delegate";
import { BuyerSellerResponse, MaterialOfferResponse } from "../../types";
import { useSeller } from "../../hooks/useValidateSeller";

type IsMaterialOfferProps = {
  materialOffer?: MaterialOfferResponse;
  valid?: boolean;
};

const IsMaterialOffer = ({
  materialOffer,
  valid = false,
}: IsMaterialOfferProps) => {
  const [openDelegate, setOpenDelegate] = useState(false);

  const { data: seller } = useSeller();

  const isRejected = seller?.filter(
    (item: BuyerSellerResponse) =>
      item?.material?.id === materialOffer?.material?.id
  )[0]?.rejected;

  const handleDelegate = () => setOpenDelegate(true);
  const handleCloseDelegate = () => setOpenDelegate(false);

  return (
    <Fragment>
      <div className="relative col-span-2 lg:col-span-1">
        <div className="mb-4 flex w-full justify-end">
          <button
            type="button"
            onClick={handleDelegate}
            className="uppercase inline-flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-xs font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light"
          >
            <ArrowPathIcon className="-ml-1 mr-2 h-5 w-5 text-white" />
            <span>{i18next.t("delegate.delegate")}</span>
          </button>
        </div>
        <div className="shadow p-4 rounded border border-spectum relative">
          {isRejected && (
            <span className="mb-5 px-4 py-2 inline-flex rounded text-xs leading-5 font-semibold bg-red-700 text-white uppercase">
              {i18next.t("tenders.seller.rejected")}
            </span>
          )}
          <h4 className="text-xs font-bold text-gray-600 uppercase pt-4">
            {i18next.t("tenders.seller.myApplication")}
          </h4>

          <h1 className="text-2xl font-bold text-gray-900">
            {materialOffer?.material?.globalMaterialName}{" "}
            <span className="text-xs text-gray-500">
              {materialOffer?.material?.ean}
            </span>
          </h1>
          {valid ? (
            <div className="px-4 py-2 flex items-center gap-2 rounded text-xs leading-5 font-semibold bg-green-700 text-white uppercase absolute right-4 top-4">
              <CheckCircleIcon className="h-4 w-4 text-white" />{" "}
              {i18next.t("offer.validatedFlag")}
            </div>
          ) : null}
          <div className="bg-white overflow-hidden">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-3">
                <dt className="uppercase text-xs font-medium text-gray-500 pb-1">
                  {i18next.t("offer.origin")}
                </dt>
                <dd className="text-xs text-gray-900">
                  {materialOffer?.origin?.name}
                </dd>
              </div>
            </dl>
            {materialOffer && materialOffer?.certifications?.length > 0 && (
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-3">
                  <dt className="uppercase text-xs font-medium text-gray-500 pb-1">
                    {i18next.t("certifications.certifications")}
                  </dt>
                  <dd className="text-xs text-gray-900">
                    {materialOffer?.certifications
                      ?.map((cert) => cert.materialCertification.name)
                      .join(", ")}
                  </dd>
                </div>
              </dl>
            )}
            {materialOffer?.comment && (
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-3">
                  <dt className="uppercase text-xs font-medium text-gray-500 pb-1">
                    {i18next.t("offer.comments")}
                  </dt>
                  <dd className="text-xs text-gray-900">
                    {materialOffer.comment}
                  </dd>
                </div>
              </dl>
            )}
          </div>
          {materialOffer?.specificationFileUrl && (
            <div className="bg-white overflow-hidden">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-3">
                  <dt className="uppercase text-xs font-medium text-gray-500 pb-1">
                    {i18next.t("newMaterialForm.specification")}
                  </dt>
                  <dd className="text-xs text-gray-900">
                    {materialOffer?.specificationFileUrl === NO_SPEC ? (
                      IT_DOESNT_HAVE_SPEC
                    ) : (
                      <a
                        href={materialOffer?.specificationFileUrl}
                        target="_blank"
                        rel="noreferrer"
                        className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-xs leading-4 font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
                      >
                        <ArrowDownTrayIcon
                          className="-ml-0.5 mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                        {i18next.t("cta.download")}
                      </a>
                    )}
                  </dd>
                </div>
              </dl>
            </div>
          )}
          {materialOffer && materialOffer?.state === SAMPLE_STATE.REQUESTED && (
            <ContactInformationCard
              user={materialOffer?.material?.owner}
              isSample
            />
          )}
          {materialOffer?.state === SAMPLE_STATE.VERIFIED && (
            <div className="p-2 flex items-center gap-3 shadow border rounded text-xs mt-10">
              <CheckBadgeIcon
                className="h-7 w-7 text-blue-500"
                aria-hidden="true"
              />
              <p>{i18next.t("offer.sampleVerified")}</p>
            </div>
          )}
          {materialOffer?.state === SAMPLE_STATE.REJECTED && (
            <div className="p-2 flex items-center gap-3 shadow border rounded text-xs mt-10">
              <XCircleIcon
                className="h-7 w-7 text-red-500"
                aria-hidden="true"
              />
              <p>{i18next.t("offer.sampleRejected")}</p>
            </div>
          )}
        </div>
      </div>
      <Slide open={openDelegate} setOpen={setOpenDelegate}>
        <Delegate
          onCloseDelegate={handleCloseDelegate}
          isDelegateMaterialOffer
          materialOffer={materialOffer}
        />
      </Slide>
    </Fragment>
  );
};

export default IsMaterialOffer;

import { Link } from "react-router-dom";
import { Pill } from "../..";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import { formatDate } from "../../../utils";
import { OrderResponse } from "../../../types";

type JointOrdersTableRowProps = {
  order: OrderResponse;
  quantity: number;
};

const JointOrdersTableRow = ({ order, quantity }: JointOrdersTableRowProps) => {
  return (
    <tr key={order.id}>
      <td className="px-2 py-2 whitespace-nowrap text-xxs text-gray-900">
        <Link
          to={`/order/multiple/${order.id}?multipleOrderId=${order.multipleOrder?.id}`}
          className="truncate hover:text-gray-900 font-bold uppercase"
        >
          {order?.internalCode ? order?.internalCode : " - "}
        </Link>
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-xxs text-gray-900">
        <Link
          to={`/order/multiple/${order.id}?multipleOrderId=${order.multipleOrder?.id}`}
          className="truncate hover:text-gray-900 font-semibold uppercase"
        >
          {order.multipleOrder?.name ?? order.name}
        </Link>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xxs text-gray-500">
          {formatDate(order.startDate)}
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xxs text-gray-500">
          {formatDate(order.finishDate)}
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xxs text-gray-500">{quantity}</div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <Pill order={order} />
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-xxs text-gray-500">
        <Link
          to={`/order/multiple/${order.id}?multipleOrderId=${order.multipleOrder?.id}`}
          className="text-indigo-600 hover:text-indigo-900"
        >
          <ChevronDoubleRightIcon className="h-6 w-6" />
        </Link>
      </td>
    </tr>
  );
};

export default JointOrdersTableRow;

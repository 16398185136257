import { useState } from "react";
import { Link } from "react-router-dom";
import i18next from "../../i18n";
import { DeliveriesTable, Loader } from "../../components";
import { useDeliveries } from "../../hooks/useDeliveries";
import { DeliveryState } from "../../types/deliveries.type";

const Deliveries = () => {
  const [page, setPage] = useState(0);

  const { data: deliveries, isLoading: isLoadingDeliveries } = useDeliveries(
    DeliveryState.SCHEDULED,
    page
  );

  return (
    <div className="px-4 mt-6">
      <div className="md:flex items-center justify-between mb-4">
        <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate uppercase">
          {i18next.t("navigation.delivery")}
        </h2>
        <Link
          to="/new-delivery"
          type="button"
          className="px-4 py-2 border border-transparent shadow-sm text-xs font-medium rounded text-white bg-spectum-dark hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum sm:order-1 uppercase mt-4"
        >
          {i18next.t("deliveries.new")}
        </Link>
      </div>
      {isLoadingDeliveries ? (
        <Loader />
      ) : (
        <>
          {deliveries && deliveries?.totalElements > 0 ? (
            <DeliveriesTable
              pageNumber={page}
              setPageNumber={setPage}
              deliveries={deliveries}
            />
          ) : (
            <p className="text-sm leading-7 text-gray-900 sm:truncate">
              {i18next.t("deliveries.noDeliveries")}
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default Deliveries;

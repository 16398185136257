import { useState } from "react";
import { connect } from "react-redux";
import i18next from "../../i18n";
import { Button, Loader, Messages } from "..";
import { mapStateToProps } from "../../utils";
import { formatDate } from "../../containers/Chat/Chat";
import {
  ChatRequest,
  ChatResponse,
  CombinedBuyerSellerMaterialOfferResponse,
  MaterialResponse,
  OrderResponse,
  OrderState,
} from "../../types";

type ChatPropsBase = {
  chat: ChatResponse;
  isMultiple?: boolean;
  material?: MaterialResponse;
  onCreateChat: (chat: ChatRequest) => void;
  isLoadingChat: boolean;
  onCreateMessage: (message: any) => void;
};

type ChatPropsWithOrder = ChatPropsBase & {
  isOrder?: true;
  order: OrderResponse;
  seller?: never; // seller is not allowed when isOrder is true
};

type ChatPropsWithSeller = ChatPropsBase & {
  isOrder?: false;
  order?: never; // order is not allowed when isOrder is false
  seller?: CombinedBuyerSellerMaterialOfferResponse;
};

type ChatProps = ChatPropsWithOrder | ChatPropsWithSeller;

const Chat = ({
  chat,
  order,
  seller,
  isOrder,
  material,
  isMultiple,
  onCreateChat,
  isLoadingChat,
  onCreateMessage,
}: ChatProps) => {
  const [message, setMessage] = useState("");

  const handleSendMessage = () => {
    if (!message || !chat) return;
    onCreateMessage({ text: message, chat: { id: chat.id } });
    setMessage("");
  };

  const onSumbitNewChat = () => {
    if (chat) return;
    if (isOrder) {
      if (isMultiple && order?.multipleOrder?.id) {
        onCreateChat({
          multipleOrder: { id: order?.multipleOrder?.id },
          order: { id: order?.id },
        });
      } else {
        onCreateChat({
          order: { id: order?.id },
        });
      }
    } else {
      material &&
        seller?.materialOffer?.user?.id &&
        onCreateChat({
          material: { id: material.id },
          supplier: { id: seller.materialOffer.user.id },
        });
    }
  };

  return (
    <>
      {isLoadingChat ? (
        <Loader />
      ) : (
        <div>
          <div className="mb-4">
            {isOrder ? (
              order?.state === OrderState.OPEN ? (
                <>
                  {!chat && (
                    <p className="text-spectum font-bold text-sm uppercase">
                      {i18next.t("chat.startOrder")}
                    </p>
                  )}
                  <p className="text-xs mt-3 uppercase font-bold">
                    {i18next.t("chat.disclaimer")}
                  </p>
                </>
              ) : (
                <p className="text-xs mt-3 uppercase font-bold">
                  {i18next.t("chat.finished")}
                </p>
              )
            ) : (
              <p className="text-spectum font-bold text-xs uppercase">
                {i18next.t("chat.talkTo")}
              </p>
            )}
            {!isOrder && (
              <p className="uppercase font-bold text-xs">
                {seller?.buyerSeller?.seller?.fullName} -{" "}
                {seller?.buyerSeller?.seller?.company?.name}
              </p>
            )}
          </div>
          {chat && chat.messages.length > 0 && (
            <span className="text-gray-600 text-xs">
              {i18next.t("chat.lastMessage")}
              {formatDate(chat.messages[chat.messages.length - 1].updatedAt)}
            </span>
          )}
          <div className="p-3 mx-auto bg-white rounded shadow flex flex-col space-y-4 gap-4 border">
            {chat && (
              <>
                <div className="h-400 overflow-y-scroll">
                  {chat.messages.length === 0 ? (
                    <p className="text-xs">{i18next.t("chat.noMessages")}</p>
                  ) : (
                    <div
                      id="messages"
                      className="flex flex-col space-y-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch"
                    >
                      {chat.messages.map((message) => (
                        <Messages
                          key={message.id}
                          conv={message}
                          isOrder={isOrder}
                          orderOwnerId={order?.user?.id}
                        />
                      ))}
                    </div>
                  )}
                </div>
                {isOrder && order?.state === OrderState.OPEN && (
                  <div>
                    <input
                      type="text"
                      className="p-2 border-gray-300 rounded w-full text-xs shadow"
                      value={message}
                      max={255}
                      onChange={(e) => {
                        if (e.target.value.length === 255) return;
                        setMessage(e.target.value);
                      }}
                      placeholder={i18next.t("chat.write")}
                    />
                    <span className="text-xs">{message.length} / 255</span>
                    <Button style="w-full" onClick={handleSendMessage}>
                      {i18next.t("cta.send")}
                    </Button>
                  </div>
                )}
                {!isOrder && (
                  <div>
                    <input
                      type="text"
                      className="p-2 border-gray-300 rounded w-full text-xs shadow"
                      value={message}
                      max={255}
                      onChange={(e) => {
                        if (e.target.value.length === 255) return;
                        setMessage(e.target.value);
                      }}
                      placeholder={i18next.t("chat.write")}
                    />
                    <span className="text-xs">{message.length} / 255</span>
                    <Button style="w-full" onClick={handleSendMessage}>
                      {i18next.t("cta.send")}
                    </Button>
                  </div>
                )}
              </>
            )}
            {!isOrder && !chat && (
              <Button onClick={onSumbitNewChat}>
                {i18next.t("chat.start")}
              </Button>
            )}
            {isOrder && order?.state === OrderState.OPEN && !chat && (
              <Button onClick={onSumbitNewChat}>
                {i18next.t("chat.start")}
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default connect(mapStateToProps)(Chat);

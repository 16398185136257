import { Link } from "react-router-dom";
import { Pill } from "../..";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import {
  cutIdOnFirstHyphen,
  mapStateToProps,
  formatDate,
} from "../../../utils";
import { connect } from "react-redux";
import { OrderResponse, UserResponse } from "../../../types";

type PurchaseRequestsTableRowProps = {
  order: OrderResponse;
  isRequester: boolean;
  requester: UserResponse;
  purchaseRequestId?: string;
  multiplePurchaseRequestId?: string;
};

const PurchaseRequestsTableRow = ({
  order,
  isRequester,
  requester,
  purchaseRequestId,
  multiplePurchaseRequestId,
}: PurchaseRequestsTableRowProps) => {
  return (
    <tr key={order.id}>
      <td className="px-2 py-2 whitespace-nowrap text-xxs text-gray-900 uppercase font-semibold">
        <Link
          to={
            multiplePurchaseRequestId
              ? `/purchase-request/multiple/${multiplePurchaseRequestId}`
              : `/purchase-request/${purchaseRequestId}`
          }
        >
          {purchaseRequestId
            ? cutIdOnFirstHyphen(purchaseRequestId)
            : cutIdOnFirstHyphen(multiplePurchaseRequestId)}
        </Link>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xxs text-gray-900 font-semibold uppercase">
          <Link
            to={
              multiplePurchaseRequestId
                ? `/purchase-request/multiple/${multiplePurchaseRequestId}`
                : `/purchase-request/${purchaseRequestId}`
            }
          >
            {order.name}
          </Link>
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xxs text-gray-500">
          {formatDate(order.startSupplyDate)}{" "}
          {order.endSupplyDate !== order.startSupplyDate &&
            `- ${formatDate(order.endSupplyDate)}`}
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xxs text-gray-500">
          {isRequester ? order.user.fullName : requester?.fullName}
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xxs text-gray-500">
          {formatDate(order.createdAt)}
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <Pill order={order} />
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-xxs text-gray-500">
        <Link
          to={
            multiplePurchaseRequestId
              ? `/purchase-request/multiple/${multiplePurchaseRequestId}`
              : `/purchase-request/${purchaseRequestId}`
          }
          className="text-indigo-600 hover:text-indigo-900"
        >
          <ChevronDoubleRightIcon className="h-6 w-6" />
        </Link>
      </td>
    </tr>
  );
};

export default connect(mapStateToProps)(PurchaseRequestsTableRow);

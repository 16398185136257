import { Pagination } from "..";
import i18next from "../../i18n";
import PurchaseRequestsOrdersTableRow from "./PurchaseRequestsOrdersTableRow/PurchaseRequestsOrdersTableRow";
import { PurchaseRequestOrderPaginatedResponse } from "../../types";

const tableTh = [
  { id: 1, name: i18next.t("tenders.tenderSummary.name") },
  { id: 2, name: i18next.t("tenders.tenderSummary.tenderPeriod") },
  { id: 3, name: i18next.t("purchaseRequests.createdAt") },
  { id: 4, name: i18next.t("tenders.tenderSummary.status") },
];

type PurchaseRequestsOrdersTableProps = {
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  purchaseRequestsOrders: PurchaseRequestOrderPaginatedResponse;
};

const PurchaseRequestsOrdersTable = ({
  pageNumber,
  setPageNumber,
  purchaseRequestsOrders,
}: PurchaseRequestsOrdersTableProps) => {
  return (
    <div className="mt-4">
      <div className="flex flex-col mt-2">
        <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden rounded">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {tableTh?.map((th) => (
                  <th
                    key={th.id}
                    scope="col"
                    className="px-2 py-2 text-left text-xxs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {th.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {purchaseRequestsOrders &&
                purchaseRequestsOrders.content?.map((ord) => (
                  <PurchaseRequestsOrdersTableRow key={ord.id} order={ord} />
                ))}
            </tbody>
          </table>
          {purchaseRequestsOrders && (
            <Pagination
              results={purchaseRequestsOrders?.numberOfElements}
              total={purchaseRequestsOrders?.totalElements}
              amountOfPages={purchaseRequestsOrders?.totalPages}
              currentPage={pageNumber}
              setPageNumber={setPageNumber}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PurchaseRequestsOrdersTable;

import { Link } from "react-router-dom";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import { numberFormatter } from "../../../utils";
import { MaterialType } from "../../../types";

type MaterialTableRowProps = {
  id: string;
  type?: MaterialType;
  service?: boolean;
  status: boolean;
  responsible?: string;
  annualVolume?: number;
  deliveryPlace?: string;
  merchancyCode: string;
  deliveryFormat?: string;
  measurementUnit: string;
  suppliersQuantity?: number;
  globalMaterialName: string;
  activeOrdersQuantity?: number;
  supplierValidatedQuantity?: number;
};

const MaterialTableRow = ({
  id,
  type,
  service,
  status,
  responsible,
  annualVolume,
  deliveryPlace,
  merchancyCode,
  deliveryFormat,
  measurementUnit,
  suppliersQuantity,
  globalMaterialName,
  activeOrdersQuantity,
  supplierValidatedQuantity,
}: MaterialTableRowProps) => {
  return (
    <tr key={id}>
      <td
        className={`px-2 py-2 whitespace-nowrap text-xxs border-l-4 ${
          status ? "border-green-600" : "border-red-500"
        }`}
      >
        {merchancyCode}
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-xxs uppercase font-semibold">
        <div className="flex items-center">
          <Link to={`/material/${id}`} className="truncate hover:text-gray-600">
            <span>{globalMaterialName}</span>
          </Link>
        </div>
      </td>
      {!service && (
        <td className="px-2 py-2 whitespace-nowrap">
          <div className="text-xxs text-gray-500">
            {annualVolume
              ? `${numberFormatter.format(annualVolume)} ${measurementUnit}`
              : " - "}
          </div>
        </td>
      )}
      {!service && type === MaterialType.PI && (
        <td className="px-2 py-2 whitespace-nowrap text-xxs text-gray-500 truncate">
          {deliveryFormat ? deliveryFormat : " - "}
        </td>
      )}
      {deliveryPlace && (
        <td className="px-2 py-2 text-xxs text-gray-500 truncate">
          {deliveryPlace}
        </td>
      )}
      {responsible && (
        <td className="px-2 py-2 text-xxs text-gray-500">{responsible}</td>
      )}
      {Number(supplierValidatedQuantity) >= 0 && (
        <td className="px-2 py-2 text-xxs text-gray-500">
          {supplierValidatedQuantity}
        </td>
      )}
      {Number(suppliersQuantity) >= 0 && (
        <td className="px-2 py-2 text-xxs text-gray-500">
          {suppliersQuantity}
        </td>
      )}
      {Number(activeOrdersQuantity) >= 0 && (
        <td className="px-2 py-2 text-xxs text-gray-500">
          {activeOrdersQuantity}
        </td>
      )}
      <td className="px-2 py-2 whitespace-nowrap text-right text-xxs font-medium">
        <Link
          to={`/material/${id}`}
          className="text-indigo-600 hover:text-indigo-900"
        >
          <ChevronDoubleRightIcon className="h-6 w-6" />
        </Link>
      </td>
    </tr>
  );
};

export default MaterialTableRow;

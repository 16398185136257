import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { mapStateToProps, mapDispatchToProps } from "../../utils";
import {
  ExclamationTriangleIcon,
  ArrowDownTrayIcon,
  ChatBubbleOvalLeftEllipsisIcon,
} from "@heroicons/react/24/outline";
import {
  Award,
  GoBackArrow,
  Loader,
  Modal,
  Offer,
  Slide,
  ContactInformationCard,
  Chat,
  BiddingSummary,
  AlertBanner,
} from "../../components";
import { useID } from "../../hooks";
import OffersOnOrders from "./OffersOnOrders";
import SuppliersSelected from "./SuppliersSelected";
import BidHeader from "./BidHeader";
import { OfferResponse, OrderState, UserResponse } from "../../types";
import {
  onGetOrderComparison,
  useDeleteOrder,
  useOrder,
  useSelectedOrder,
} from "../../hooks/useOrders";
import {
  useCreateChat,
  useCreateMessage,
  useOrderChat,
} from "../../hooks/useChat";
import { useAcceptOffer, useOffersOnOrders } from "../../hooks/useOffers";
import { useInvitedOrderSuppliers } from "../../hooks/useSuppliers";
import { queryKeys } from "../../hooks/queryKeys";
import BidResultTable from "./BidResultTable";

type IsBuyerBidProps = {
  user: UserResponse;
  isCompliant: boolean;
  isRequester: boolean;
  onOpenNotification: (message: string) => void;
};

const IsBuyerBid = ({
  user,
  isCompliant,
  isRequester,
  onOpenNotification,
}: IsBuyerBidProps) => {
  const { t } = useTranslation();
  const id = useID();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [openSlide, setOpenSlide] = useState(false);
  const [cancelMessage, setCancelMessage] = useState("");
  const [itemToSlide, setItemToSlide] = useState<OfferResponse | null>(null);
  const [isAward, setIsAward] = useState(false);

  const queryClient = useQueryClient();

  const { data: order, isLoading: isLoadingOrders } = useOrder(id);

  const { data: offersOnOrders, isLoading: isLoadingOffers } =
    useOffersOnOrders(id);

  const isTendersSucceded = offersOnOrders?.content?.find(
    (el: OfferResponse) => el.selected
  );

  const { data: orderSelected } = useSelectedOrder(id);

  const { data: chat, isLoading: isLoadingChat } = useOrderChat(id);

  const { mutate: mutateChat, isPending: isCreatingChat } = useCreateChat([
    queryKeys.CHAT,
  ]);

  const { mutate: mutateMessage, isPending: isSendingMessage } =
    useCreateMessage([queryKeys.CHAT]);

  const { data: invitedOrderSuppliers } = useInvitedOrderSuppliers(id);

  const { mutate: acceptOffer, isPending: isAcceptingOffer } = useAcceptOffer(
    () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.OFFERS_ON_ORDERS, id],
      });
      queryClient.invalidateQueries({ queryKey: [queryKeys.TENDER, id] });
      return onOpenNotification(t("tenders.data.acceptOffer"));
    },
    () => onOpenNotification(t("newMaterialForm.error"))
  );

  const { mutate: deleteOrder, isPending: isDeleting } = useDeleteOrder(
    () => {
      onOpenNotification(t("tenders.data.cancelMessage"));
      return navigate("/orders");
    },
    () => onOpenNotification(t("newMaterialForm.error"))
  );

  const isOwner = user?.id === order?.material?.owner?.id;

  const handleCancel = () => {
    if (!cancelMessage) return;
    if (!order) return;
    deleteOrder({
      orderId: order.id,
      order: {
        ...order,
        comment: cancelMessage,
        state: OrderState.CANCELLED,
      },
    });
    setOpen(false);
  };

  const handleItemClick = (e: any, item: OfferResponse) => {
    if (order?.state === OrderState.OPEN) return;
    setItemToSlide(item);
    setOpenSlide(true);
  };

  const onChangeMessage = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCancelMessage(e.target.value);

  if (isLoadingOrders || isLoadingOffers || isDeleting || isAcceptingOffer)
    return <Loader isFullScreen />;

  if (order) {
    return (
      <Fragment>
        <Modal
          open={open}
          setOpen={setOpen}
          hasMessage
          disabled={!cancelMessage}
          onChangeMessage={onChangeMessage}
          icon={
            <ExclamationTriangleIcon
              className="h-6 w-6 text-red-600"
              aria-hidden="true"
            />
          }
          title={t("tenders.data.cancel")}
          message={t("tenders.data.cancelQuestion")}
          isCancel
          onClickSuccess={handleCancel}
          onClickCancel={() => setOpen(false)}
        />
        <div className="min-h-full flex">
          <div className="flex flex-col w-0 flex-1">
            <div className="py-8 xl:py-10 relative">
              <div className="w-full px-8 flex justify-between items-center gap-4">
                <GoBackArrow />
                {!isRequester && (
                  <>
                    {!isAward ? (
                      <div className="flex gap-4 items-center w-full justify-end">
                        {order.state !== OrderState.CANCELLED &&
                          order.state !== OrderState.SUCCEED && (
                            <button
                              className="uppercase px-4 py-2 shadow-sm text-xs font-medium rounded text-red-500 border border-red-500 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light"
                              onClick={() => setOpen(true)}
                            >
                              <span>{t("tenders.data.cancel")}</span>
                            </button>
                          )}
                        <button
                          onClick={() => setOpenChat(true)}
                          className="uppercase flex items-center gap-2 px-4 py-2 shadow-sm text-xs font-medium rounded border border-black bg-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                        >
                          <ChatBubbleOvalLeftEllipsisIcon className="h-4 w-4" />
                          {t("chat.title")}
                          {chat?.messages?.length > 0 && (
                            <span className="text-white border border-blue-600 bg-blue-600 px-1 rounded-full">
                              {chat.messages.length}
                            </span>
                          )}
                        </button>
                        {(order.state === OrderState.SUCCEED ||
                          order.state === OrderState.CLOSED) && (
                          <button
                            onClick={() => onGetOrderComparison(id)}
                            className="uppercase flex items-center px-4 py-2 shadow-sm text-xs font-medium rounded border border-black bg-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                          >
                            <ArrowDownTrayIcon
                              className="-ml-0.5 mr-2 h-4 w-4"
                              aria-hidden="true"
                            />
                            {t("cta.downloadComparison")}
                          </button>
                        )}
                        {!isTendersSucceded &&
                        order.state === OrderState.CLOSED &&
                        isOwner &&
                        offersOnOrders &&
                        offersOnOrders?.content?.length > 0 ? (
                          <>
                            <button
                              onClick={() => setIsAward(true)}
                              className="uppercase px-4 py-2 shadow-sm text-xs font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light"
                            >
                              {t("tenders.data.accept")}
                            </button>
                          </>
                        ) : null}
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="uppercase inline-flex justify-center px-4 py-2 shadow-sm text-xs font-medium rounded text-black bg-white border border-black"
                        onClick={() => setIsAward(false)}
                      >
                        {t("cta.cancel")}
                      </button>
                    )}
                  </>
                )}
              </div>
              <div className="mx-auto px-4 lg:px-8 xl:grid xl:grid-cols-3 gap-4">
                <div className="col-span-3">
                  <BidHeader
                    order={order}
                    orderName={order.name}
                    selected={isTendersSucceded?.selected}
                    setOpen={setOpen}
                  />

                  {orderSelected && isTendersSucceded && (
                    <>
                      <BidResultTable
                        title={t("tenders.seller.acceptedJoint")}
                        selectedOffers={orderSelected}
                      />
                      {orderSelected.map((offer: OfferResponse) => (
                        <ContactInformationCard
                          key={offer.id}
                          user={offer.user}
                        />
                      ))}
                    </>
                  )}

                  {!isAward ? (
                    <BiddingSummary order={order} />
                  ) : (
                    <>
                      {offersOnOrders?.content && (
                        <Award
                          order={order}
                          offers={offersOnOrders?.content}
                          onAcceptOffer={acceptOffer}
                          onCancel={() => setIsAward(false)}
                          setIsAward={() => setIsAward(false)}
                        />
                      )}
                    </>
                  )}
                </div>
                {!isAward ? (
                  <div className="col-span-3 grid grid-cols-2 gap-4">
                    <div className="col-span-2 md:col-span-1">
                      <h2
                        id="activity-title"
                        className="text-lg font-medium text-gray-900 border-b pb-6 pt-8"
                      >
                        {t("tenders.data.receivedOffers")}
                      </h2>
                      <OffersOnOrders
                        offOnOrd={offersOnOrders?.content}
                        handleItemClick={handleItemClick}
                        state={order.state as OrderState}
                        isCompliant={isCompliant}
                      />
                    </div>
                    <div className="col-span-2 md:col-span-1 pt-10">
                      <SuppliersSelected
                        suppliers={order.suppliers}
                        invitedSuppliers={invitedOrderSuppliers}
                      />
                    </div>

                    {order.state === OrderState.CLOSED &&
                      offersOnOrders?.content?.length === 0 && (
                        <AlertBanner title={"tenders.data.noOffersMessage"} />
                      )}

                    {order.state === OrderState.CANCELLED && order.comment && (
                      <AlertBanner
                        title={"tenders.data.cancelledMessage"}
                        comments={order.comment}
                      />
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <Slide open={openSlide} setOpen={setOpenSlide}>
          {itemToSlide && <Offer offer={itemToSlide} />}
        </Slide>
        <Slide open={openChat} setOpen={setOpenChat}>
          <Chat
            isOrder
            chat={chat}
            order={order}
            onCreateChat={mutateChat}
            isLoadingChat={isLoadingChat || isSendingMessage || isCreatingChat}
            onCreateMessage={mutateMessage}
          />
        </Slide>
      </Fragment>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(IsBuyerBid);

import { useRef, useState, useReducer, useMemo } from "react";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import i18next from "../../i18n";
import { AwardItem, Button, Modal } from "..";
import { connect } from "react-redux";
import { mapStateToProps } from "../../utils";
import {
  AwardOrderRequest,
  OfferResponse,
  OffersToAccept,
  OrderResponse,
} from "../../types";

const actions = {
  CLEAR_OFFERS: "CLEAR_OFFERS",
  UPDATE_VOLUME: "UPDATE_VOLUME",
  UPDATE_PRICE: "UPDATE_PRICE",
  DELETE_OFFER: "DELETE_OFFER",
  UPDATE_ON_CHECKBOX_CHANGE: "UPDATE_ON_CHECKBOX_CHANGE",
};

type AwardProps = {
  offers: OfferResponse[];
  onAcceptOffer: (offers: AwardOrderRequest[]) => void;
  onCancel: () => void;
  setIsAward: () => void;
  order: OrderResponse;
};

type EventState = {
  offersToAccept: OffersToAccept[];
  errors: any[];
  invalid: any[];
};

const Award = ({
  offers,
  onAcceptOffer,
  onCancel,
  setIsAward,
  order,
}: AwardProps) => {
  const cancelButtonRef = useRef(null);
  const [openAccept, setOpenAccept] = useState(false);

  const [event, updateEvent] = useReducer(
    (state: EventState, action: any) => {
      let newState = { ...state };
      switch (action.type) {
        case actions.UPDATE_ON_CHECKBOX_CHANGE:
          {
            const offerIndex = newState.offersToAccept.findIndex(
              (offer) => offer.id === action.id
            );
            if (offerIndex >= 0) {
              newState.offersToAccept[offerIndex] = {
                ...newState.offersToAccept[offerIndex],
                volume: action.volume,
                price: action.price,
              };
            } else {
              newState.offersToAccept.push({
                id: action.id,
                volume: action.volume,
                price: action.price,
                orderId: action.orderId,
                material: action.material,
                offer: action.offer,
              });
            }
          }
          break;
        case actions.UPDATE_VOLUME: {
          const offerIndex = newState.offersToAccept.findIndex(
            (offer) => offer.id === action.id
          );
          if (offerIndex >= 0) {
            newState.offersToAccept[offerIndex] = {
              ...newState.offersToAccept[offerIndex],
              volume: action.volume,
            };
          } else {
            newState.offersToAccept.push({
              id: action.id,
              volume: action.volume,
              orderId: action.orderId,
            } as OffersToAccept);
          }
          break;
        }
        case actions.UPDATE_PRICE: {
          const offerIndex = newState.offersToAccept.findIndex(
            (offer) => offer.id === action.id
          );
          if (offerIndex >= 0) {
            newState.offersToAccept[offerIndex] = {
              ...newState.offersToAccept[offerIndex],
              price: action.price,
            };
          } else {
            newState.offersToAccept.push({
              id: action.id,
              price: action.price,
              orderId: action.orderId,
            } as OffersToAccept);
          }
          break;
        }
        case actions.DELETE_OFFER:
          {
            newState = {
              ...newState,
              offersToAccept: state.offersToAccept.filter(
                (offer) => offer.id !== action.id
              ),
            };
          }
          break;
      }

      const volumeSums: any = {};
      newState.offersToAccept.forEach((offer) => {
        const isInvalidVolume = offer.volume <= 0;
        const isInvalidPrice = offer.price <= 0;
        const errorIndex = newState.invalid.findIndex(
          (error) => error.id === offer.id
        );

        if (isInvalidVolume || isInvalidPrice) {
          const errorMessage = i18next.t("tenders.errors.invalid");

          if (errorIndex === -1) {
            newState.invalid.push({ id: offer.id, message: errorMessage });
          } else {
            newState.invalid[errorIndex] = {
              id: offer.id,
              message: errorMessage,
            };
          }
        } else if (errorIndex !== -1) {
          // If previously had an error and now corrected, remove the error
          newState.invalid.splice(errorIndex, 1);
        }

        volumeSums[offer.orderId] =
          (volumeSums[offer.orderId] || 0) +
          parseFloat(String(offer.volume || 0));
      });

      Object.keys(volumeSums).forEach((orderId) => {
        const totalVolume = volumeSums[orderId];
        const requestedVolume = order.quantity;
        const errorIndex = newState.errors.findIndex(
          (error) => error.orderId === orderId
        );

        if (totalVolume > requestedVolume) {
          const material = newState.offersToAccept.find(
            (o) => o.orderId === orderId
          )?.material;
          const errorMessage = `${i18next.t(
            "tenders.errors.message"
          )} ${totalVolume} > ${requestedVolume}`;

          if (errorIndex === -1) {
            newState.errors.push({ orderId, message: errorMessage, material });
          } else {
            newState.errors[errorIndex] = {
              orderId,
              material,
              message: errorMessage,
            };
          }
        } else if (errorIndex !== -1) {
          newState.errors.splice(errorIndex, 1);
        }
      });

      return newState;
    },
    { offersToAccept: [], errors: [], invalid: [] }
  );

  const formattedOffers = useMemo(() => {
    if (!offers) return [];

    const offersWithTotalCost = offers.map((offer) => ({
      ...offer,
      totalCost: offer.price * offer.order.quantity,
    }));

    const bestOffer = offersWithTotalCost.reduce((prev, current) =>
      prev.totalCost < current.totalCost ? prev : current
    );

    const offersWithBestFlag = offersWithTotalCost.map((offer) => ({
      ...offer,
      isBest: offer.id === bestOffer.id,
    }));

    return offersWithBestFlag
      .slice()
      .sort((a, b) =>
        a.order.material.globalMaterialName.localeCompare(
          b.order.material.globalMaterialName
        )
      );
  }, [offers]);

  const handleOfferConfirmation = () => setOpenAccept(true);

  const onAwardTender = () => {
    const formattedOffers = event.offersToAccept.map((offer) => ({
      offer: {
        ...offer.offer,
        price: parseFloat(String(offer.price)),
        quantity: parseFloat(String(offer.volume)),
        oldQuantity: order.quantity,
        oldPrice: offer.offer.price,
        selected: true,
      },
      replace: false,
    }));
    onAcceptOffer(formattedOffers);
    setOpenAccept(false);
    setIsAward();
  };

  return (
    <>
      <Modal
        open={openAccept}
        setOpen={setOpenAccept}
        icon={
          <CheckCircleIcon
            className="h-6 w-6 text-green-600"
            aria-hidden="true"
          />
        }
        title={i18next.t("tenders.data.accept")}
        message={i18next.t("tenders.data.acceptQuestion")}
        onClickSuccess={onAwardTender}
        onClickCancel={() => setOpenAccept(false)}
      />
      <div className="bg-white mt-4">
        <h2 className="uppercase text-2xl font-bold pb-2">
          {i18next.t("award.awardTo")}
        </h2>
        <div className="flex">
          <div
            className="rounded border-t border-l"
            style={{ height: "fit-content" }}
          >
            <div className="text-center flex w-full p-4 border-b border-white"></div>
            <div className="text-center flex w-full p-4 border-b"></div>
            <div className="text-left flex w-full border-b">
              <p className="text-xs w-full pl-1 py-4 uppercase font-bold text-spectum max-w-9 truncate">
                {order?.material?.globalMaterialName}
              </p>
            </div>
          </div>
          <div
            className="border-t border-l border-r"
            style={{ height: "fit-content" }}
          >
            <div className="text-center flex w-full p-4 border-white"></div>
            <div className="text-center flex w-full">
              <p className="text-xs w-full border-t border-b p-2 uppercase font-bold text-gray-600">
                {i18next.t("tenders.form.requested")}
              </p>
            </div>
            <div className="flex w-full border-b text-center">
              <p className="text-xs w-full pl-1 py-4 uppercase font-bold max-w-9 truncate">
                {order?.quantity} {order?.measurementUnit}
              </p>
            </div>
          </div>
          <div className="flex overflow-x-scroll">
            {formattedOffers?.map((offer) => (
              <AwardItem
                key={offer.id}
                offer={offer}
                actions={actions}
                updateEvent={updateEvent}
                event={event}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="w-full pt-4">
        {event.errors.map((error) => (
          <div
            key={error.orderId}
            className="rounded bg-yellow-50 p-3 text-xs mb-4 border-yellow-600 border"
          >
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon
                  className="h-5 w-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="font-medium text-yellow-800">
                  {i18next.t("tenders.errors.title")}
                </h3>
                <div className="mt-2 text-xs text-yellow-700">
                  <ul className="list-disc space-y-1 pl-5">
                    <li key={error.orderId}>{error.message}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
        {event.invalid.map((error) => (
          <div
            key={error.orderId}
            className="rounded bg-yellow-50 p-3 text-xs mb-4 border-yellow-600 border"
          >
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon
                  className="h-5 w-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="font-medium text-yellow-800">
                  {i18next.t("tenders.errors.title")}
                </h3>
                <div className="mt-2 text-xs text-yellow-700">
                  <ul className="list-disc space-y-1 pl-5">
                    <li key={error.orderId}>{error.message}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:flex sm:px-6 gap-4 mt-4">
        <Button
          variant="cancel"
          onClick={() => {
            updateEvent({ type: actions.CLEAR_OFFERS, offersToAccept: [] });
            onCancel();
          }}
          ref={cancelButtonRef}
        >
          {i18next.t("cta.cancel")}
        </Button>
        <Button
          variant={
            event.offersToAccept.length > 0 && event.invalid.length === 0
              ? "success"
              : "disabled"
          }
          onClick={handleOfferConfirmation}
          disabled={
            event.offersToAccept.length === 0 || event.invalid.length > 0
          }
        >
          {i18next.t("tenders.data.accept")}
        </Button>
      </div>
    </>
  );
};

export default connect(mapStateToProps)(Award);

import { CategoryResponse } from "./category.type";
import {
  CertificationRequest,
  CertificationResponse,
} from "./certification.type";
import { CompanyResponse } from "./company.type";
import { ContentPaginatedInterface } from "./content.type";
import { PN_TYPES } from "./enums";
import { SiteResponse } from "./site.type";
import { UserResponse } from "./user.type";
import { VolumeResponse } from "./volume.type";

export enum MaterialType {
  PI = "PI",
  NPI = "NPI",
  SERVICE = "SERVICE",
}

export interface MaterialResponse {
  active: boolean;
  application: string;
  annualVolume: number;
  category: CategoryResponse;
  certifications: CertificationResponse[];
  coin: string;
  company: CompanyResponse;
  confident: boolean;
  confidentSpec: boolean;
  createdAt: string;
  deleted: boolean;
  deliveryFormat: string;
  deliveryPlace: SiteResponse;
  ean: string;
  fileURL: string;
  globalMaterialName: string;
  id: string;
  incoTerm: string;
  measurementUnit: string;
  owner: UserResponse;
  user: UserResponse;
  comments: string;
  type: MaterialType;
  updatedAt: string;
  volume: VolumeResponse | null;
}

export interface MaterialRequest {
  specifications: string;
  coin: string;
  measurementUnit: string;
  ean: string;
  incoTerm?: string;
  application?: string;
  comments?: string;
  category: { id: string };
  deliveryPlace: { id: string } | null;
  fileURL?: string;
  globalMaterialName: string;
  deliveryFormat?: string;
  annualVolume?: number;
  confidentSpec: boolean;
  confident: boolean;
  certifications: CertificationRequest[];
  type: PN_TYPES;
}

export interface MaterialWithSuppliersResponse {
  material: MaterialResponse;
  activeOrdersQuantity: number;
  supplierValidatedQuantity: number;
  suppliersQuantity: number;
}

export type MaterialWithSuppliersPaginatedResponse =
  ContentPaginatedInterface<MaterialWithSuppliersResponse>;

export type MaterialPaginatedResponse =
  ContentPaginatedInterface<MaterialResponse>;

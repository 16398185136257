import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "../axios-orders";
import {
  JointOfferResponse,
  OfferPaginatedResponse,
  OfferRequest,
  OfferResponse,
} from "../types";
import { queryKeys } from "./queryKeys";

const onGetOffer = async (offerId: string) => {
  const { data } = await axios.get(`/offer/${offerId}`);
  return data;
};

export const useOffer = (offerId: string) =>
  useQuery<OfferResponse>({
    queryKey: [queryKeys.OFFER, offerId],
    queryFn: () => onGetOffer(offerId),
    enabled: !!offerId,
  });

const onGetOffersOnOrders = async (orderId: string, page = 0) => {
  const { data } = await axios.get(`/offer/order/${orderId}/${page}`);
  return data;
};

export const useOffersOnOrders = (orderId: string) =>
  useQuery<OfferPaginatedResponse>({
    queryKey: [queryKeys.OFFERS_ON_ORDERS, orderId],
    queryFn: () => onGetOffersOnOrders(orderId),
    enabled: !!orderId,
  });

const onGetMultipleOffersOnOrders = async (orderId: string) => {
  const { data } = await axios.get(`/offer/multiple/order/${orderId}`);
  return data;
};

export const useMultipleOffersOnOrders = (multipleOrderId: string) =>
  useQuery<JointOfferResponse>({
    queryKey: [queryKeys.MULTIPLE_OFFERS_ON_ORDERS, multipleOrderId],
    queryFn: () => onGetMultipleOffersOnOrders(multipleOrderId),
    enabled: !!multipleOrderId,
  });

const onGetMultipleOffers = async (page = 0) => {
  const { data } = await axios.get(`/offer/list/multiple/${page}`);
  return data;
};

export const useMultipleOffers = (page?: number) =>
  useQuery({
    queryKey: [queryKeys.MULTIPLE_OFFERS, page],
    queryFn: () => onGetMultipleOffers(page),
    select: (data) => {
      const items = Object.keys(data).map((key) => data[key]);
      return items;
    },
  });

const onGetUserOffer = async (orderId: string, userId: string) => {
  const { data } = await axios.get(`offer/order/${orderId}/user/${userId}`);
  return data;
};

export const useUserOffer = (orderId: string, userId: string) =>
  useQuery({
    queryKey: [queryKeys.OFFER, orderId, userId],
    queryFn: () => onGetUserOffer(orderId, userId),
    enabled: !!userId,
  });

const onGetMultipleOffer = async (orderId: string) => {
  const { data } = await axios.get(`/offer/multiple/order/user/${orderId}`);
  return data;
};

export const useMultipleOffer = (orderId: string) =>
  useQuery({
    queryKey: [queryKeys.MULTIPLE_OFFER, orderId],
    queryFn: () => onGetMultipleOffer(orderId),
    select: (data: JointOfferResponse) => {
      const offers = Object.keys(data).map((key) => data[key]);
      return offers;
    },
  });

const onCreateOffer = async (body: OfferRequest) => {
  const { data } = await axios.post("/offer", body);
  return data;
};

export const useCreateOffer = (
  onSuccess: (res: any) => void,
  onError: () => void
) =>
  useMutation({
    mutationFn: onCreateOffer,
    onSuccess,
    onError,
  });

const onCreateMultipleOffer = async (body: any) => {
  const { data } = await axios.post("/offer/multiple", body);
  return data;
};

export const useCreateMultipleOffer = (
  onSuccess: (res: any) => void,
  onError?: () => void
) =>
  useMutation({
    mutationFn: onCreateMultipleOffer,
    onSuccess,
    onError,
  });

const onAcceptOffer = async (offers: any) => {
  const { data } = await axios.put(`/offer/list/select`, offers);
  return data;
};

export const useAcceptOffer = (onSuccess: () => void, onError: () => void) =>
  useMutation({
    mutationFn: onAcceptOffer,
    onSuccess,
    onError,
  });

const onUpdateMultipleInverseOffer = async (offers: any) => {
  const { data } = await axios.post(`/offer/updatePrice/list`, offers);
  return data;
};

export const useUpdateMultipleInverseOffer = (
  onSuccess: () => void,
  onError: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: onUpdateMultipleInverseOffer,
    onSuccess: () => {
      onSuccess();
      return queryClient.invalidateQueries({
        queryKey: [queryKeys.MULTIPLE_OFFER],
      });
    },
    onError,
  });
};

const onUpdateInverseOffer = async ({
  offerId,
  price,
}: {
  offerId: string;
  price: string | number;
}) => {
  const { data } = await axios.get(`/offer/updatePrice/${offerId}/${price}`);
  return data;
};

export const useUpdateInverseOffer = (
  onSuccess: () => void,
  onError: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: onUpdateInverseOffer,
    onSuccess: () => {
      onSuccess();
      return queryClient.invalidateQueries({
        queryKey: [queryKeys.OFFER, queryKeys.POSITION],
      });
    },
    onError,
  });
};

const onGetOffers = async (page = 0) => {
  const { data } = await axios.get(`/offer/list/${page}`);
  return data;
};

export const useOffers = (page: number) =>
  useQuery({
    queryKey: [queryKeys.OFFERS, page],
    queryFn: () => onGetOffers(page),
  });

import { Routes, Route } from "react-router-dom";
import {
  NewSiteForm,
  NewUserForm,
  Layout,
  NewDeliveryForm,
} from "../../../components";
import {
  CreateBuyerMaterial,
  DelegateCnt,
  EditMaterial,
  EditSite,
  Home,
  IsBuyerBid,
  MaterialCnt,
  Materials,
  NotFound,
  Profile,
  Orders,
  Order,
  Sites,
  ValidateUser,
  User,
  Users,
  EditUser,
  IsJointBuyerBid,
  Chat,
  OrderByRequest,
  PurchaseRequestDetail,
  PurchaseRequestMultipleDetail,
  PurchaseRequests,
  Deliveries,
} from "../../../containers";

const BuyerRoutes = () => {
  return (
    <Routes>
      <Route path="/validate-user" element={<ValidateUser />} />
      <Route element={<Layout />}>
        <Route path="/materials" element={<Materials />} />
        <Route path="/delegate" element={<DelegateCnt />} />
        <Route path="/material/:id" element={<MaterialCnt />} />
        <Route path="/material/edit/:id" element={<EditMaterial />} />
        <Route path="/new-material" element={<CreateBuyerMaterial />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/order" element={<Order />} />
        <Route path="/order/:id" element={<IsBuyerBid />} />
        <Route path="/purchase-requests" element={<PurchaseRequests />} />
        <Route path="/deliveries" element={<Deliveries />} />
        <Route path="/new-delivery" element={<NewDeliveryForm />} />
        <Route path="/order-by-request" element={<OrderByRequest />} />
        <Route path="/order/multiple/:id" element={<IsJointBuyerBid />} />
        <Route
          path="/purchase-request/:id"
          element={<PurchaseRequestDetail />}
        />
        <Route
          path="/purchase-request/multiple/:id"
          element={<PurchaseRequestMultipleDetail />}
        />
        <Route path="/profile" element={<Profile />} />
        <Route path="/user/material/:id" element={<MaterialCnt />} />
        <Route path="/user/:id" element={<User />} />
        <Route path="/user/edit/:id" element={<EditUser />} />
        <Route path="/users/create" element={<NewUserForm />} />
        <Route path="/users" element={<Users />} />
        <Route path="/sites" element={<Sites />} />
        <Route path="/site/:id" element={<EditSite />} />
        <Route path="/new-site" element={<NewSiteForm />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default BuyerRoutes;

import { format, parseISO } from "date-fns";
import { enUS, es } from "date-fns/locale";
import { ORDER_TYPES } from "../types/enums";
import {
  BIDDING_LABELS,
  BIDDING_TYPES,
  BIDDING_TYPES_LABELS,
  DELIVERY_STATES,
  PILL_LABELS,
} from "./enums";
import {
  OrderResponse,
  OrderState,
  DeliveryInterface,
  DeliveryState,
} from "../types";

export const currencyFormatter = (number?: number, coin?: string) => {
  if (!number) return;
  const currency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: coin ? coin : "ARS",
  }).format(number);

  return currency;
};

export const cutIdOnFirstHyphen = (id?: string) => {
  if (!id) return "";
  return id.split("-")[0];
};

export const taxIdFormatter = (taxId: string) => {
  if (!taxId) return;
  const trimmedText = taxId.trim();
  const cleanedText = trimmedText.replace(/[^a-zA-Z0-9]/g, "");
  return cleanedText;
};

export const addressFormatter = (address: string) => {
  if (!address) return address;
  const result = address?.split(",").slice(-2).join(",");
  return result;
};

export const numberFormatter = new Intl.NumberFormat("es-AR", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  roundingIncrement: 5,
});

export const getNumberOfDays = (end: string) => {
  const date1 = new Date();
  const date2 = new Date(end);
  date2.setDate(date2.getDate() + 1);
  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = date2.getTime() - date1.getTime();
  const diffInDays = Math.round(diffInTime / oneDay);
  return diffInDays;
};

export const updateObject = (oldObject: any, updatedProperties: any) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const calculateMinimumDecrement = (
  price?: number,
  percentage?: number
) => {
  if (!price || !percentage) return;
  const res = (price * percentage) / 100;
  return (price - res).toFixed(2);
};

export const capitalize = (sentence: string, first?: boolean) => {
  if (!sentence) return;
  const words = sentence.split(" ");
  if (first) {
    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
  }
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  return words.join(" ");
};

export const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

export const getMonth = (date: string) => {
  const period = new Intl.DateTimeFormat("es-AR", {
    month: "long",
  }).format(new Date(date));
  return capitalize(period);
};

export const labelType = (type: ORDER_TYPES) => {
  if (type === BIDDING_TYPES.INVERSE) return BIDDING_TYPES_LABELS.INVERSE;
  if (type === BIDDING_TYPES.NORMAL) return BIDDING_TYPES_LABELS.NORMAL;
  if (type === BIDDING_TYPES.JOINT) return BIDDING_TYPES_LABELS.JOINT;
  if (type === BIDDING_TYPES.INVERSE_JOINT)
    return BIDDING_TYPES_LABELS.INVERSE_JOINT;
};

export const formatDate = (date: string) => {
  const language = localStorage.getItem("i18nextLng");
  const isEnglish =
    language === "en" ||
    language === "en-US" ||
    language === "en-GB" ||
    language === "en-AU" ||
    language === "en-CA" ||
    language === "en-IN" ||
    language === "en-NZ" ||
    language === "en-ZA";
  const langEs = !isEnglish;

  try {
    const parsedDate = parseISO(date);
    const formattedDate = format(parsedDate, "dd/MM/yyyy", {
      locale: langEs ? es : enUS,
    });
    return formattedDate.replace(
      /(\s[a-z])/g,
      (match) => match.toUpperCase() // Capitalizes the month
    );
  } catch (err) {
    console.error(err);
    return "";
  }
};

interface ResolverOptions {
  selected?: boolean;
  isSeller?: boolean;
}

export const pillResolver = (
  order: OrderResponse,
  options: ResolverOptions = {}
): string => {
  const { selected = false, isSeller = false } = options;

  if (!order?.state) return ""; // Guard clause for invalid or missing order

  switch (order.state) {
    case OrderState.SUCCEED:
      if (isSeller) {
        return selected
          ? PILL_LABELS.OFFER_SELECTED
          : PILL_LABELS.OFFER_NOT_SELECTED;
      }
      return PILL_LABELS.OFFER_SELECTED;

    case OrderState.PENDING:
      return BIDDING_LABELS.SCHEDULED;

    case OrderState.OPEN:
      return BIDDING_LABELS.IN_PROGRESS;

    case OrderState.CLOSED:
      return PILL_LABELS.OFFER_PENDING_OF_SELECTION;

    case OrderState.CANCELLED:
      return PILL_LABELS.BIDDING_CANCELED;

    case OrderState.DELIVERED:
      return PILL_LABELS.DELIVERED;

    case OrderState.REJECTED:
      return PILL_LABELS.REJECTED;

    case OrderState.DRAFT:
      return PILL_LABELS.DRAFT;

    default:
      return ""; // Return empty if no matching state
  }
};

export const deliveryResolver = (delivery: DeliveryInterface): string => {
  switch (delivery.state) {
    case DeliveryState.SCHEDULED:
      return DELIVERY_STATES.SCHEDULED;
    case DeliveryState.REJECTED:
      return DELIVERY_STATES.REJECTED;
    case DeliveryState.ACCEPTED:
      return DELIVERY_STATES.ACCEPTED;
    case DeliveryState.IN_PROGRESS:
      return DELIVERY_STATES.IN_PROGRESS;
    case DeliveryState.CANCELLED:
      return DELIVERY_STATES.CANCELLED;
    case DeliveryState.DELIVERED:
      return DELIVERY_STATES.DELIVERED;
    case DeliveryState.COMPLETED:
      return DELIVERY_STATES.COMPLETED;
    default:
      return ""; // Default to an empty string for unknown states
  }
};

import { Fragment } from "react";
import i18next from "../../../i18n";
import { AlertBanner, InfoItem, OrderFileList } from "../../../components";
import {
  BIDDING_TYPES,
  formatDate,
  labelType,
  mapStateToProps,
} from "../../../utils";
import { connect } from "react-redux";
import {
  MaterialResponse,
  MaterialType,
  ORDER_TYPES,
  OrderState,
} from "../../../types";
import { InformationCircleIcon } from "@heroicons/react/24/solid";

const thInverse = [
  { id: 1, name: i18next.t("tenders.tenderSummary.code") },
  { id: 2, name: i18next.t("tenders.tenderSummary.material") },
  { id: 3, name: i18next.t("tenders.tenderSummary.purpose") },
  { id: 4, name: i18next.t("tenders.tenderSummary.tenderPeriod") },
];

const thNormal = [
  { id: 1, name: i18next.t("tenders.tenderSummary.code") },
  { id: 2, name: i18next.t("tenders.tenderSummary.material") },
  { id: 3, name: i18next.t("tenders.tenderSummary.volume") },
  { id: 4, name: i18next.t("tenders.tenderSummary.tenderPeriod") },
];

type PurchaseRequestSummaryDetailProps = {
  type: ORDER_TYPES;
  isBid?: boolean;
  multiplePurchaseRequest?: any;
  purchaseRequest?: any;
  isRequester: boolean;
  setOpenSlide: (val: boolean) => void;
  setMaterialToShow: (material: MaterialResponse) => void;
};

const PurchaseRequestSummaryDetail = ({
  type,
  isBid,
  purchaseRequest,
  isRequester,
  setOpenSlide,
  setMaterialToShow,
}: PurchaseRequestSummaryDetailProps) => {
  let tableTh = thNormal;
  const isService =
    purchaseRequest?.order?.material?.type === MaterialType.SERVICE;

  if (isService) {
    tableTh = thInverse;
  }

  return (
    <>
      <div className="col-span-3 sm:flex sm:items-center border-b pb-4">
        <div>
          {isBid && (
            <h1 className="text-xl font-semibold text-gray-900 mb-2">
              {i18next.t("tenders.summary")}
            </h1>
          )}
          {type && (
            <InfoItem
              name={i18next.t("tenders.form.tenderType")}
              description={labelType(type)}
            />
          )}
          {isRequester ? (
            <InfoItem
              name={i18next.t("tenders.tenderSummary.responsible")}
              description={purchaseRequest?.order?.user?.fullName}
            />
          ) : (
            <InfoItem
              name={i18next.t("userTypes.requester")}
              description={purchaseRequest?.user?.fullName}
            />
          )}
          {purchaseRequest?.order?.startDate && (
            <InfoItem
              name={i18next.t("tenders.tenderSummary.initialDate")}
              description={formatDate(purchaseRequest?.order?.startDate)}
            />
          )}
          {purchaseRequest?.order?.finishDate && (
            <InfoItem
              name={i18next.t("tenders.tenderSummary.endDate")}
              description={formatDate(purchaseRequest?.order?.finishDate)}
            />
          )}
          {purchaseRequest?.order?.startDateValue && (
            <InfoItem
              name={i18next.t("tenders.tenderSummary.initialDate")}
              description={formatDate(purchaseRequest?.order?.startDateValue)}
            />
          )}
          {purchaseRequest?.order?.endDateValue && (
            <InfoItem
              name={i18next.t("tenders.tenderSummary.endDate")}
              description={formatDate(purchaseRequest?.order?.endDateValue)}
            />
          )}
          {purchaseRequest?.order?.paymentMethod && (
            <InfoItem
              name={i18next.t("tenders.tenderSummary.paymentMethod")}
              description={purchaseRequest?.order?.paymentMethod}
            />
          )}
          {purchaseRequest?.order?.paymentTerm && (
            <InfoItem
              name={i18next.t("tenders.tenderSummary.paymentTerm")}
              description={
                purchaseRequest?.order?.paymentTerm === 1
                  ? ` ${i18next.t("opportunities.table.day")}`
                  : ` ${i18next.t("opportunities.table.days")}`
              }
            />
          )}

          {purchaseRequest?.order?.files?.length > 0 && (
            <OrderFileList order={purchaseRequest?.order} />
          )}
        </div>
      </div>
      {purchaseRequest?.order?.state === OrderState.REJECTED && (
        <AlertBanner
          title={"purchaseRequests.rejected"}
          comments={purchaseRequest?.comments}
        />
      )}
      <div className="col-span-3 -mx-4 flex flex-col sm:-mx-6 md:mx-0">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              {tableTh.map((t) => (
                <th
                  key={t.id}
                  scope="col"
                  className="uppercase py-3.5 pl-4 pr-3 text-left text-xxs font-semibold text-gray-900 sm:pl-6 md:pl-0"
                >
                  {t.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr className="border-b border-gray-200">
              <td className="py-4 text-xxs">
                <div className="font-medium text-gray-900 uppercase">
                  {isBid
                    ? purchaseRequest.material?.ean
                    : purchaseRequest.order.material.ean}
                </div>
              </td>
              <td className="py-4 text-xxs">
                <div className="font-medium text-gray-900 uppercase flex items-center gap-3">
                  {isBid
                    ? purchaseRequest.material?.globalMaterialName
                    : purchaseRequest.order.material.globalMaterialName}
                  <button
                    onClick={() => {
                      let material = isBid
                        ? purchaseRequest.material
                        : purchaseRequest.order.material;
                      setMaterialToShow(material);
                      setOpenSlide(true);
                    }}
                  >
                    <InformationCircleIcon className="h-5 w-5 text-spectum" />
                  </button>
                </div>
              </td>
              {!isService ? (
                <td className="py-4 text-xxs text-gray-500">
                  {purchaseRequest.order.quantity}{" "}
                  {purchaseRequest.order.measurementUnit}
                </td>
              ) : (
                <td className="py-4 text-xxs text-gray-500">
                  {purchaseRequest.order.purpose}
                </td>
              )}
              {(type === BIDDING_TYPES.INVERSE ||
                type === BIDDING_TYPES.INVERSE_JOINT) && (
                <Fragment>
                  <td className="py-4 text-xxs text-gray-500">
                    {purchaseRequest.order.initialPrice}{" "}
                    {purchaseRequest.order.coin}
                  </td>
                  <td className="py-4 text-xxs text-gray-500">
                    {purchaseRequest.order.minimumDecrement} %
                  </td>
                </Fragment>
              )}
              <td className="py-4 text-xxs text-gray-500">
                {purchaseRequest.order.startSupplyDate ===
                purchaseRequest.order.endSupplyDate ? (
                  formatDate(purchaseRequest.order.startSupplyDate)
                ) : (
                  <>
                    {formatDate(purchaseRequest.order.startSupplyDate)} -{" "}
                    {formatDate(purchaseRequest.order.endSupplyDate)}
                  </>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default connect(mapStateToProps)(PurchaseRequestSummaryDetail);

import * as Yup from "yup";
import { PN_TYPE } from "./enums";
import i18next from "../i18n";
import { DELIVERY_TYPE, ORDER_TYPES, PN_TYPES } from "../types/enums";

const MaterialTypeEnum = {
  PI: "PI",
  NPI: "NPI",
  SERVICE: "SERVICE",
};

const regexPassword =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@\-#.`~_:;"|($)[\]{}%^&?¿*<>+=])(?!.*\s).{10,30}$/;

export const registerValidationSchema = Yup.object().shape({
  companyName: Yup.string().required(i18next.t("validations.companyName")),
  fullName: Yup.string().required(i18next.t("validations.fullName")),
  phone: Yup.string().required(i18next.t("validations.phone")),
  taxId: Yup.string().required(i18next.t("validations.taxId")),
  country: Yup.string().required(i18next.t("validations.country")),
  email: Yup.string()
    .email(i18next.t("validations.validEmail"))
    .required(i18next.t("validations.email")),
  confirmEmail: Yup.string().oneOf(
    [Yup.ref("email"), undefined],
    i18next.t("validations.confirmEmail")
  ),
  password: Yup.string()
    .matches(regexPassword, i18next.t("validations.passwordTerms"))
    .min(12, i18next.t("validations.characters"))
    .required(i18next.t("validations.password")),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), undefined],
    i18next.t("validations.confirmPassword")
  ),
  age: Yup.boolean().oneOf([true], i18next.t("validations.age")),
  termsAndConditions: Yup.boolean().oneOf(
    [true],
    i18next.t("validations.termsAndConditions")
  ),
  informationAgreement: Yup.boolean().notRequired(),
});

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18next.t("validations.validEmail"))
    .max(255)
    .required(i18next.t("validations.email")),
  password: Yup.string().required(i18next.t("validations.password")),
});

export const resetPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18next.t("validations.validEmail"))
    .max(255)
    .required(i18next.t("validations.email")),
});

export const changePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .matches(regexPassword, i18next.t("validations.passwordTerms"))
    .min(12, i18next.t("validations.characters"))
    .required(i18next.t("validations.newPassword")),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), undefined],
    i18next.t("validations.confirmPassword")
  ),
});

export const newPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18next.t("validations.validEmail"))
    .max(255)
    .required(i18next.t("validations.email")),
  password: Yup.string()
    .matches(regexPassword, i18next.t("validations.passwordTerms"))
    .min(12, i18next.t("validations.characters"))
    .required(i18next.t("validations.password")),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), undefined],
    i18next.t("validations.confirmPassword")
  ),
});

export const newMaterialValidationSchema = (pnType: string) => {
  if (pnType === PN_TYPE.PI) {
    return Yup.object().shape({
      type: Yup.string()
        .oneOf(Object.values(MaterialTypeEnum))
        .required(i18next.t("validations.selectType")),
      incoTerm: Yup.string().required(i18next.t("validations.incoTerm")),
      ean: Yup.string().required(i18next.t("validations.ean")),
      globalMaterialName: Yup.string().required(
        i18next.t("validations.globalMaterialName")
      ),
      annualVolume: Yup.number()
        .typeError(i18next.t("validations.globalMaterialName"))
        .min(1, i18next.t("validations.annualVolume")),
      measurementUnit: Yup.string().required(
        i18next.t("validations.selectMeasurementUnit")
      ),
      coin: Yup.string().required(i18next.t("validations.coin")),
      deliveryPlace: Yup.string().required(
        i18next.t("validations.selectDeliveryPlace")
      ),
      deliveryFormat: Yup.string().required(
        i18next.t("validations.deliveryFormat")
      ),
      specifications: Yup.string().required(
        i18next.t("validations.specifications")
      ),
    });
  }
  if (pnType === PN_TYPE.NPI) {
    return Yup.object().shape({
      type: Yup.string()
        .oneOf(Object.values(MaterialTypeEnum))
        .required(i18next.t("validations.selectType")),
      ean: Yup.string().required(i18next.t("validations.ean")),
      globalMaterialName: Yup.string().required(
        i18next.t("validations.globalMaterialName")
      ),
      deliveryPlace: Yup.string().required(
        i18next.t("validations.deliveryPlace")
      ),
      specifications: Yup.string().required(
        i18next.t("validations.specifications")
      ),
    });
  }
  if (pnType === PN_TYPE.SERVICE) {
    return Yup.object().shape({
      ean: Yup.string().required(i18next.t("validations.ean")),
      globalMaterialName: Yup.string().required(
        i18next.t("validations.globalMaterialName")
      ),
      deliveryPlace: Yup.string().required(
        i18next.t("validations.deliveryPlace")
      ),
      coin: Yup.string().required(i18next.t("validations.coin")),
    });
  }
};

export const editMaterialValidationSchema = (pnType: PN_TYPES) => {
  switch (pnType) {
    case PN_TYPES.PI:
      return Yup.object().shape({
        globalMaterialName: Yup.string().required(
          i18next.t("validations.globalMaterialName")
        ),
        annualVolume: Yup.number()
          .typeError(i18next.t("validations.price"))
          .min(1, i18next.t("validations.annualVolume")),
        measurementUnit: Yup.string().required(
          i18next.t("validations.measurementUnit")
        ),
        deliveryFormat: Yup.string().required(
          i18next.t("validations.deliveryFormat")
        ),
        coin: Yup.string().required(i18next.t("validations.coin")),
        deliveryPlace: Yup.string(),
        fileURL: Yup.string(),
        comments: Yup.string(),
        application: Yup.string(),
        incoTerm: Yup.string(),
        confidentSpec: Yup.bool(),
        isMaterialConfidential: Yup.bool(),
        certifications: Yup.array(),
      });
    case PN_TYPES.NPI:
    case PN_TYPES.SERVICE:
      return Yup.object().shape({
        globalMaterialName: Yup.string().required(
          i18next.t("validations.globalMaterialName")
        ),
        annualVolume: Yup.number()
          .typeError(i18next.t("validations.globalMaterialName"))
          .min(1, i18next.t("validations.annualVolume")),
        coin: Yup.string().required(i18next.t("validations.coin")),
        measurementUnit: Yup.string(),
        deliveryFormat: Yup.string(),
        deliveryPlace: Yup.string(),
        fileURL: Yup.string(),
        comments: Yup.string(),
        application: Yup.string(),
        incoTerm: Yup.string(),
        confidentSpec: Yup.bool(),
        isMaterialConfidential: Yup.bool(),
        certifications: Yup.array(),
      });
    default:
      throw new Error(`Unsupported PN_TYPE: ${pnType}`);
  }
};

export const editSiteValidationSchema = Yup.object().shape({
  name: Yup.string().required(i18next.t("validations.siteName")),
  internalCode: Yup.string().required(i18next.t("validations.internalCode")),
});

export const editUserValidationSchema = (isLoggedUser: boolean) => {
  if (isLoggedUser) {
    return Yup.object().shape({
      fullName: Yup.string().required(i18next.t("validations.fullName")),
      phone: Yup.string().required(i18next.t("validations.phoneNumber")),
    });
  }
  return Yup.object().shape({
    fullName: Yup.string().required(i18next.t("validations.fullName")),
    phone: Yup.string().required(i18next.t("validations.phoneNumber")),
    userCompanyType: Yup.string().required(
      i18next.t("validations.userCompanyType")
    ),
  });
};

export const newSiteValidationSchema = Yup.object().shape({
  name: Yup.string().required(i18next.t("validations.siteName")),
  internalCode: Yup.string().required(i18next.t("validations.internalCode")),
  country: Yup.string().required(i18next.t("validations.country")),
});

export const newUserValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18next.t("validations.characters"))
    .max(255)
    .required(i18next.t("validations.characters")),
  confirmEmail: Yup.string().oneOf(
    [Yup.ref("email"), undefined],
    i18next.t("validations.confirmEmail")
  ),
  parent: Yup.string(),
  fullName: Yup.string().required(i18next.t("validations.siteName")),
  phone: Yup.string().required(i18next.t("validations.phoneNumber")),
  userCompanyType: Yup.string().required(
    i18next.t("validations.userCompanyType")
  ),
});

export const biddingValidationSchema = Yup.object().shape({
  quantity: Yup.number()
    .typeError(i18next.t("validations.globalMaterialName"))
    .min(1, i18next.t("validations.tender.volume"))
    .required(i18next.t("validations.validVolume")),
  startSupplyDate: Yup.string().required(
    i18next.t("validations.tender.period")
  ),
  endSupplyDate: Yup.string().required(i18next.t("validations.tender.period")),
  startDate: Yup.string().required(i18next.t("validations.tender.start")),
  finishDate: Yup.string().required(i18next.t("validations.tender.end")),
  paymentMethod: Yup.string().required(
    i18next.t("validations.tender.paymentMethod")
  ),
  paymentTerm: Yup.string().required(
    i18next.t("validations.tender.paymentTerm")
  ),
});

export const newOfferValidationSchema = (type?: ORDER_TYPES, max?: number) => {
  if (type === ORDER_TYPES.INVERSE || type === ORDER_TYPES.INVERSE_JOINT) {
    return Yup.object().shape({
      price: Yup.number()
        .transform((value: any, originalValue: any) => {
          if (typeof originalValue === "string") {
            const parsedValue = originalValue
              .replace(/\./g, "")
              .replace(",", ".")
              .replace("$ ", "");
            return parsedValue ? parseFloat(parsedValue) : NaN;
          }
          return value;
        })
        .min(0.00000001, i18next.t("validations.moreThanZero"))
        .max(max, `${i18next.t("validations.lowerThanX")} ${max}`)
        .required(i18next.t("validations.insertPrice")),
    });
  }
  return Yup.object().shape({
    price: Yup.number()
      .transform((value: any, originalValue: any) => {
        if (typeof originalValue === "string") {
          const parsedValue = originalValue
            .replace(/\./g, "")
            .replace(",", ".")
            .replace("$ ", "");
          return parsedValue ? parseFloat(parsedValue) : NaN;
        }
        return value;
      })
      .min(1, i18next.t("validations.moreThanZero"))
      .required(i18next.t("validations.insertPrice")),
  });
};

export const newMaterialOfferValidationSchema = (isSpec: boolean) => {
  if (isSpec) {
    return Yup.object().shape({
      origin: Yup.string().required(i18next.t("validations.origin")),
      comment: Yup.string().max(255, i18next.t("validations.comment255")),
    });
  }
  return Yup.object().shape({
    origin: Yup.string().required(i18next.t("validations.origin")),
    specificationFileUrl: Yup.string().required(
      i18next.t("validations.specifications")
    ),
    comment: Yup.string().max(255, i18next.t("validations.comment255")),
  });
};

export const purchaseRequestOrderValidationSchema = Yup.object().shape({
  name: Yup.string().required(i18next.t("validations.name")),
  spot: Yup.string().required(),
  file: Yup.mixed().required(
    i18next.t("purchaseRequestOrders.validations.file")
  ),
  startSupplyDate: Yup.date()
    .nullable()
    .transform((value: string, originalValue: string) =>
      originalValue === "" ? null : value
    )
    .required(i18next.t("purchaseRequestOrders.validations.startSupplyDate")),
  endSupplyDate: Yup.date()
    .nullable()
    .transform((value: string, originalValue: string) =>
      originalValue === "" ? null : value
    )
    .when("spot", {
      is: DELIVERY_TYPE.SPOT,
      then: (schema: any) => schema.nullable().notRequired(),
      otherwise: (schema: any) =>
        schema
          .min(
            Yup.ref("startSupplyDate"),
            i18next.t("purchaseRequestOrders.validations.endSupplyDateMin")
          )
          .required(
            i18next.t("purchaseRequestOrders.validations.endSupplyDate")
          ),
    }),
});

export const deliveryValidationSchema = Yup.object().shape({
  orderName: Yup.string().required(
    i18next.t("deliveries.form.validations.orderName")
  ),
  purchaseOrderNumber: Yup.string().required(
    i18next.t("deliveries.form.validations.purchaseOrderNumber")
  ),
  email: Yup.string()
    .email(i18next.t("validations.validEmail"))
    .required(i18next.t("deliveries.form.validations.email")),
  confirmEmail: Yup.string().oneOf(
    [Yup.ref("email"), undefined],
    i18next.t("validations.confirmEmail")
  ),
  deliveryDate: Yup.string().required(
    i18next.t("deliveries.form.validations.deliveryDate")
  ),
  deliveryTime: Yup.string().required(
    i18next.t("deliveries.form.validations.deliveryTime")
  ),
  purchaseOrderFile: Yup.string().required(
    i18next.t("deliveries.form.validations.file")
  ),
  comment: Yup.string().max(255, i18next.t("validations.comment255")),
});

export const queryKeys = {
  MATERIAL_MARKET_INFO: "MATERIAL_MARKET_INFO",
  GLOBAL_MARKET_INFO: "GLOBAL_MARKET_INFO",

  AVAILABLE_COUNTRIES: "AVAILABLE_COUNTRIES",
  COUNTRIES_BY_MATERIAL: "COUNTRIES_BY_MATERIAL",
  COUNTRIES: "COUNTRIES",

  MATERIAL_CATEGORY_BY_NAME: "MATERIAL_CATEGORY_BY_NAME",
  PRODUCT_SERVICE_FAMILY: "PRODUCT_SERVICE_FAMILY",
  PRODUCT_SERVICE_CLASS: "PRODUCT_SERVICE_CLASS",
  MERCHANCY_CODES: "MERCHANCY_CODES",
  BUY_TEAMS: "BUY_TEAMS",

  CHATS: "CHATS",
  CHAT: "CHAT",
  MULTIPLE_ORDER_CHAT: "MULTIPLE_ORDER_CHAT",

  HISTORIC: "HISTORIC",

  KPIS: "KPIS",
  POSITION: "POSITION",

  MATERIAL_COMPANIES_OPPORTUNITIES_BY_COUNTRY:
    "MATERIAL_COMPANIES_OPPORTUNITIES_BY_COUNTRY",
  MATERIAL_INVITATIONS_OPPORTUNITIES: "MATERIAL_INVITATIONS_OPPORTUNITIES",
  INVITATIONS_BY_EMAIL: "INVITATIONS_BY_EMAIL",
  INVITED_MATERIAL_SUPPLIERS: "INVITED_MATERIAL_SUPPLIERS",
  INVITED_ORDER_SUPPLIERS: "INVITED_ORDER_SUPPLIERS",

  ALL_COMPANY_MATERIALS: "ALL_COMPANY_MATERIALS",
  USER_MATERIAL_CATEGORIES: "USER_MATERIAL_CATEGORIES",
  MATERIAL: "MATERIAL",
  MATERIALS: "MATERIALS",
  FOUND_MATERIALS: "FOUND_MATERIALS",
  MATERIALS_BY_OWNER: "MATERIALS_BY_OWNER",
  USER_MATERIALS_BY_COMPANY: "USER_MATERIALS_BY_COMPANY",
  PI_MATERIALS: "PI_MATERIALS",
  NPI_MATERIALS: "NPI_MATERIALS",
  SERVICES: "SERVICES",

  ALL_MATERIALS_OFFERS: "ALL_MATERIALS_OFFERS",
  ALL_COMPANY_MATERIAL_OFFERS: "ALL_COMPANY_MATERIAL_OFFERS",
  MATERIAL_OFFER: "MATERIAL_OFFER",
  MATERIAL_OFFERS: "MATERIAL_OFFERS",
  CHECK_MATERIAL_OFFER: "CHECK_MATERIAL_OFFER",

  MATERIAL_VOLUME: "MATERIAL_VOLUME",

  OPPORTUNITIES: "OPPORTUNITIES",
  MULTIPLE_OPPORTUNITIES: "MULTIPLE_OPPORTUNITIES",

  ORDER: "ORDER",
  ORDERS: "ORDERS",
  JOINT_ORDER: "JOINT_ORDER",
  JOINT_ORDERS: "JOINT_ORDERS",
  TENDER: "TENDER",
  ORDERS_COUNT: "ORDERS_COUNT",
  POTENTIAL_SUPPLIERS: "POTENTIAL_SUPPLIERS",

  MULTIPLE_OFFERS_ON_ORDERS: "MULTIPLE_OFFERS_ON_ORDERS",
  OFFERS_ON_ORDERS: "OFFERS_ON_ORDERS",
  OFFER: "OFFER",
  OFFERS: "OFFERS",
  MULTIPLE_OFFER: "MULTIPLE_OFFER",
  MULTIPLE_OFFERS: "MULTIPLE_OFFERS",

  PURCHASE_REQUEST: "PURCHASE_REQUEST",
  PURCHASE_REQUEST_ORDERS: "PURCHASE_REQUEST_ORDERS",
  PURCHASE_REQUEST_ORDER: "PURCHASE_REQUEST_ORDER",
  MULTIPLE_PURCHASE_REQUEST: "MULTIPLE_PURCHASE_REQUEST",
  COUNT_PURCHASE_REQUESTS: "COUNT_PURCHASE_REQUESTS",
  USER_PURCHASE_REQUESTS_BY_STATE: "USER_PURCHASE_REQUESTS_BY_STATE",
  USER_PURCHASE_REQUESTS: "USER_PURCHASE_REQUESTS",
  MULTIPLE_PURCHASE_REQUESTS_BY_STATE: "MULTIPLE_PURCHASE_REQUESTS_BY_STATE",
  MULTIPLE_PURCHASE_REQUESTS: "MULTIPLE_PURCHASE_REQUESTS",

  SITE: "SITE",
  SITES: "SITES",

  USER: "USER",
  USERS_BY_COMPANY: "USERS_BY_COMPANY",

  BUYER: "BUYER",
  FOUND_BUYERS: "FOUND_BUYERS",
  SELLER: "SELLER",

  DELIVERIES: "DELIVERIES",
  DELIVERY: "DELIVERY",
};

import { Pagination } from "..";
import i18next from "../../i18n";
import DeliveriesTableRow from "./DeliveriesTableRow/DeliveriesTableRow";
import { PaginatedDeliveryInterface } from "../../types";

const tableTh = [
  { id: 1, name: i18next.t("deliveries.form.orderName") },
  { id: 2, name: i18next.t("deliveries.form.purchaseOrderNumber") },
  { id: 3, name: i18next.t("deliveries.form.email") },
  { id: 4, name: i18next.t("deliveries.form.deliveryDate") },
  { id: 5, name: i18next.t("deliveries.form.deliveryTime") },
  { id: 6, name: i18next.t("deliveries.form.purchaseOrderFile") },
  { id: 7, name: i18next.t("tenders.tenderSummary.status") },
];

type DeliveriesTableProps = {
  deliveries: PaginatedDeliveryInterface;
  pageNumber: number;
  setPageNumber: (page: number) => void;
};

const DeliveriesTable = ({
  deliveries,
  pageNumber,
  setPageNumber,
}: DeliveriesTableProps) => {
  return (
    <div className="mt-4">
      <div className="flex flex-col mt-2">
        <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden rounded">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {tableTh?.map((th) => (
                  <th
                    key={th.id}
                    scope="col"
                    className="px-2 py-2 text-left text-xxs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {th.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {deliveries?.content.map((delivery) => (
                <DeliveriesTableRow key={delivery.id} delivery={delivery} />
              ))}
            </tbody>
          </table>
          <Pagination
            results={deliveries?.numberOfElements}
            total={deliveries?.totalElements}
            amountOfPages={deliveries?.totalPages}
            currentPage={pageNumber}
            setPageNumber={setPageNumber}
          />
        </div>
      </div>
    </div>
  );
};

export default DeliveriesTable;

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "../axios-orders";
import { queryKeys } from "./queryKeys";
import {
  DeliveryInterface,
  DeliveryState,
  PaginatedDeliveryInterface,
} from "../types/deliveries.type";

const onGetDeliveries = async (state: DeliveryState, page = 0) => {
  const { data } = await axios.get(`/deliveries/state/${state}/${page}`);
  return data;
};

export const useDeliveries = (state: DeliveryState, page = 0) =>
  useQuery<PaginatedDeliveryInterface>({
    queryKey: [queryKeys.DELIVERIES, page],
    queryFn: () => onGetDeliveries(state, page),
    retry: 1,
  });

const onGetDelivery = async (deliveryId: string) => {
  const { data } = await axios.get(`/deliveries/${deliveryId}`);
  return data;
};

export const useDelivery = (deliveryId: string) =>
  useQuery<DeliveryInterface>({
    queryKey: [queryKeys.DELIVERY, deliveryId],
    queryFn: () => onGetDelivery(deliveryId),
    enabled: !!deliveryId,
    retry: 1,
  });

const onCreateDelivery = async (body: any) => {
  const { data } = await axios.post("/deliveries", body);
  return data;
};

export const useCreateDelivery = (
  onSuccess: (res: any) => void,
  onError?: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: onCreateDelivery,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.DELIVERIES] });
      onSuccess(res);
    },
    onError,
  });
};

const onUpdateDelivery = async ({
  deliveryId,
  body,
}: {
  deliveryId: string;
  body: any;
}) => {
  const { data } = await axios.put(`/deliveries/${deliveryId}`, body);
  return data;
};

export const useUpdateDelivery = (
  onSuccess: (res: any) => void,
  onError?: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: onUpdateDelivery,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.DELIVERY] });
      onSuccess(res);
    },
    onError,
  });
};

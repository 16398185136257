import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "../axios-orders";
import {
  MaterialRequest,
  MaterialResponse,
  MaterialType,
  MaterialWithSuppliersPaginatedResponse,
} from "../types";
import { queryKeys } from "./queryKeys";

export type MaterialOpportunitiesByLastIdType = {
  countryIds: string[];
  page: number;
  type: MaterialType;
  categoryIds: string[];
};

export type SearchMaterialOpportunitiesByLastIdType = {
  query: string;
  page: number;
  type: MaterialType;
  categoryIds: string[];
  countryIds: string[];
};

const onGetAllCompanyMaterials = async (
  type: MaterialType | string,
  page = 0
) => {
  if (!type) return;
  const { data } = await axios.get(`/material/company/type/${type}/${page}`);
  return data;
};

export const useAllCompanyMaterials = (
  input: MaterialType | string | number,
  page: number
) => {
  // Determine material type and selectedTab based on the input type
  let type: MaterialType | string = "";
  let selectedTab: number | undefined;

  // If input is a number, assume it represents the selectedTab
  if (typeof input === "number") {
    selectedTab = input;
    // Map selectedTab to corresponding MaterialType
    if (selectedTab === 0) type = MaterialType.PI;
    if (selectedTab === 1) type = MaterialType.NPI;
    if (selectedTab === 2) type = MaterialType.SERVICE;
  } else {
    // Otherwise, assume input is a MaterialType or string
    type = input;
  }

  // UseQuery to fetch materials based on the resolved type and page
  return useQuery({
    queryKey: [queryKeys.ALL_COMPANY_MATERIALS, page, type],
    queryFn: () => onGetAllCompanyMaterials(type, page),
    enabled: !!type,
  });
};

const onGetMaterial = async (materialId: string) => {
  const { data } = await axios.get(`/material/${materialId}`);
  return data;
};

export const useMaterial = (materialId: string) =>
  useQuery<MaterialResponse>({
    queryKey: [queryKeys.MATERIAL, materialId],
    queryFn: () => onGetMaterial(materialId),
    enabled: !!materialId,
    retry: 1,
  });

const onToggleMaterialStatus = async (materialId: string) => {
  const { data } = await axios.get(`/material/toggleActivate/${materialId}`);
  return data;
};

export const useToggleMaterialStatus = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: onToggleMaterialStatus,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [queryKeys.MATERIAL] }),
  });
};

const onSearchMaterials = async (query: string, page = 0) => {
  const { data } = await axios.get(`/material/search/${query}/${page}`);
  return data;
};

export const useSearchMaterials = (searchTerm: string, page: number) =>
  useQuery({
    queryKey: [queryKeys.FOUND_MATERIALS, searchTerm, page],
    queryFn: () => onSearchMaterials(searchTerm, page),
    enabled: !!searchTerm,
  });

const onSearchAllMaterials = async (
  type: MaterialType | string,
  query: string,
  page = 0
) => {
  const { data } = await axios.get(
    `/material/company/search/${type}/${query}/${page}`
  );
  return data;
};

export const useSearchAllMaterials = (
  input: MaterialType | string | number,
  debouncedSearchTerm: string,
  page: number
) => {
  // Determine material type and selectedTab based on the input type
  let type: MaterialType | string = "";
  let selectedTab: number | undefined;

  // If input is a number, assume it represents the selectedTab
  if (typeof input === "number") {
    selectedTab = input;
    // Map selectedTab to corresponding MaterialType
    if (selectedTab === 0) type = MaterialType.PI;
    if (selectedTab === 1) type = MaterialType.NPI;
    if (selectedTab === 2) type = MaterialType.SERVICE;
  } else {
    // Otherwise, assume input is a MaterialType or string
    type = input;
  }
  return useQuery({
    queryKey: [queryKeys.FOUND_MATERIALS, page, debouncedSearchTerm, input],
    queryFn: () => onSearchAllMaterials(type, debouncedSearchTerm, page),
    enabled: !!debouncedSearchTerm,
  });
};

const onCreateMaterial = async (body: MaterialRequest) => {
  const { data } = await axios.post("/material", body);
  return data;
};

export const useCreateMaterial = (onSuccess: () => void, onError: () => void) =>
  useMutation({
    mutationFn: onCreateMaterial,
    onSuccess,
    onError,
  });

const onEditMaterial = async ({
  materialId,
  body,
}: {
  materialId: string;
  body: any;
}) => {
  const { data } = await axios.put(`/material/${materialId}`, body);
  return data;
};

export const useUpdateMaterial = (
  onSuccess: () => void,
  onError?: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: onEditMaterial,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.MATERIAL] });
      return onSuccess();
    },
    onError,
  });
};

const onGetMaterialsByOwner = async (
  page = 0
): Promise<MaterialWithSuppliersPaginatedResponse> => {
  const { data } = await axios.get(`/material/list/user/${page}`);
  return data;
};

export const useMaterialsByOwner = (page?: number) =>
  useQuery({
    queryKey: [queryKeys.MATERIALS_BY_OWNER, page],
    queryFn: () => onGetMaterialsByOwner(page),
  });

const onDelegateAllMaterials = async (userId: string) => {
  const { data } = await axios.put(`/material/delegate/list/${userId}`);
  return data;
};

export const useDelegateAllMaterials = (
  onSuccess: () => void,
  onError: () => void
) =>
  useMutation({
    mutationFn: onDelegateAllMaterials,
    onSuccess,
    onError,
  });

const onGetBackAllMaterials = async () => {
  const { data } = await axios.put(`/material/delegate/list`);
  return data;
};

export const useRetrieveAllMaterials = (
  onSuccess: () => void,
  onError: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: onGetBackAllMaterials,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.MATERIALS_BY_OWNER],
      });
      return onSuccess();
    },
    onError,
  });
};

const onGetOpportunitiesByLastIds = async ({
  countryIds,
  page = 0,
  type = MaterialType.PI,
  categoryIds,
}: MaterialOpportunitiesByLastIdType) => {
  const { data } = await axios.post(
    `/material/categories/search/${page}/${type}/last?countryIds=${countryIds.join(
      ","
    )}`,
    categoryIds
  );
  return data;
};

export const useOpportunitiesByLastId = (
  onSuccess: (res: any) => void,
  onError?: () => void
) =>
  useMutation({
    mutationFn: onGetOpportunitiesByLastIds,
    onSuccess,
    onError,
  });

const onSearchOpportunitiesByLastIds = async ({
  query,
  page = 0,
  type,
  categoryIds,
  countryIds,
}: SearchMaterialOpportunitiesByLastIdType) => {
  const { data } = await axios.post(
    `/material/categories/search/${page}/${type}/last?query=${query}&countryIds=${countryIds.join(
      ","
    )}`,
    categoryIds
  );
  return data;
};

export const useSearchOpportunitiesByLastIds = () =>
  useMutation({
    mutationFn: onSearchOpportunitiesByLastIds,
  });

const onGetAllMaterialsByCompanyAndUser = async (userId: string, page = 0) => {
  const { data } = await axios.get(`/material/company/${userId}/${page}`);
  return data;
};

export const useAllMaterialsByCompanyAndUser = (
  userId: string,
  page: number,
  isBuy?: boolean
) =>
  useQuery({
    queryKey: [queryKeys.USER_MATERIALS_BY_COMPANY, userId, page],
    queryFn: () => onGetAllMaterialsByCompanyAndUser(userId, page),
    enabled: !!userId && isBuy,
  });

const onGetMaterials = async (
  type: MaterialType,
  page = 0
): Promise<MaterialWithSuppliersPaginatedResponse> => {
  const { data } = await axios.get(`/material/type/${type}/${page}`);
  return data;
};

export const useMaterials = (
  queryKey: string,
  materialType: MaterialType,
  page: number,
  selectedTab?: number
) => {
  const materialTypes: Record<number, MaterialType> = {
    0: MaterialType.PI,
    1: MaterialType.NPI,
    2: MaterialType.SERVICE,
  };

  return useQuery({
    queryKey: [queryKey, page, selectedTab, materialType],
    queryFn: () => onGetMaterials(materialType, page),
    enabled:
      !!materialType &&
      (selectedTab === undefined ||
        materialType === materialTypes[selectedTab]),
  });
};

import i18next from "../../../i18n";

type AlertBannerProps = {
  title: string;
  comments?: string;
};

const AlertBanner = ({ title, comments }: AlertBannerProps) => {
  return (
    <div className="col-span-3 p-2 mb-2 rounded border border-red-600 bg-red-200">
      <p className="text-xxs font-bold uppercase">{i18next.t(title)}</p>
      {comments && <p className="text-xxs mt-4">{comments}</p>}
    </div>
  );
};

export default AlertBanner;

import { ChevronRightIcon, PaperClipIcon } from "@heroicons/react/24/solid";
import { connect } from "react-redux";
import i18next from "../../i18n";
import { BIDDING_TYPES, mapStateToProps, NO_SPEC } from "../../utils";
import { OfferResponse, OrderState } from "../../types";

type OfferItemProps = {
  offer: OfferResponse;
  offers?: OfferResponse[];
  handleItemClick: (e: any) => void;
  isCompliant: boolean;
};

const OfferItem = ({
  isCompliant,
  offer,
  offers,
  handleItemClick,
}: OfferItemProps) => {
  const isSelected = offers ? offers.some((offer) => offer.selected) : null;
  const stateAndCompliance =
    offer?.order?.state === OrderState.OPEN && isCompliant;

  return (
    <li>
      <div
        onClick={handleItemClick}
        className="hover:bg-gray-50 cursor-pointer flex justify-between items-center px-4 py-2"
      >
        <div className="flex p-1 justify-between w-full items-center">
          <div className="pr-3 border-black flex gap-4 items-center">
            <div>
              <p className="text-xs font-bold uppercase truncate text-spectum">
                {offer?.user?.company?.name}
              </p>
              {!stateAndCompliance &&
                (offer?.order?.type === BIDDING_TYPES.NORMAL ||
                  offer?.order?.type === BIDDING_TYPES.INVERSE) && (
                  <>
                    <div>
                      <p className="text-xs text-gray-900">
                        <span className="uppercase text-gray-700">
                          {i18next.t("award.price")}:
                        </span>{" "}
                        <span className="font-bold">
                          {offer?.price} {offer?.order?.currency} /{" "}
                          {offer?.order?.measurementUnit}
                        </span>
                      </p>
                    </div>
                    <div>
                      <p className="text-xs text-gray-900">
                        <span className="uppercase text-gray-700">
                          {i18next.t("award.volume")}:
                        </span>{" "}
                        <span className="font-bold">
                          {offer?.order?.quantity}{" "}
                          {offer?.order?.measurementUnit}
                        </span>
                      </p>
                    </div>
                  </>
                )}
            </div>
            {!stateAndCompliance &&
              (offer?.order?.type === BIDDING_TYPES.NORMAL ||
                offer?.order?.type === BIDDING_TYPES.INVERSE) &&
              offer.fileURL &&
              offer.fileURL !== NO_SPEC && (
                <PaperClipIcon className="h-5 w-5" />
              )}
          </div>
          {!stateAndCompliance && (
            <div className="flex items-center gap-4">
              {(offer?.selected || isSelected) && (
                <div>
                  <p className="p-1 text-xs font-bold bg-green-100 text-green-800 uppercase rounded">
                    {i18next.t("offer.success")}
                  </p>
                </div>
              )}

              <ChevronRightIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
      </div>
    </li>
  );
};

export default connect(mapStateToProps)(OfferItem);

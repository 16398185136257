import { connect } from "react-redux";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { Pill } from "../../components";
import { mapStateToProps } from "../../utils";
import { OrderResponse } from "../../types";

type BidHeaderProps = {
  order: OrderResponse;
  setOpen?: any;
  selected?: boolean;
  isSeller: boolean;
  orderName?: string;
};

const BidHeader = ({
  order,
  setOpen,
  selected,
  isSeller,
  orderName,
}: BidHeaderProps) => {
  return (
    <div className="md:flex md:items-center md:justify-between md:space-x-4 border-b pb-4">
      <div className="relative w-full">
        <h4 className="text-xs font-bold text-gray-600 uppercase flex items-center gap-4">
          <Pill order={order} selected={selected} isSeller={isSeller} />
        </h4>
        <div className="flex items-center justify-between pt-4">
          <h1 className="text-2xl font-bold text-gray-900 uppercase">
            {orderName}
          </h1>
        </div>

        {!selected && (
          <div className="sm:hidden pt-4 space-x-3 absolute top-0 right-2">
            <button
              className="inline-flex justify-center p-2 border border-red-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-300"
              onClick={() => setOpen(true)}
            >
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(BidHeader);

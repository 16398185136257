import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Loader, PickList } from "../..";
import { mapDispatchToProps, mapStateToProps } from "../../../utils";
import {
  MaterialOfferResponse,
  MaterialResponse,
  UserResponse,
} from "../../../types";
import { useUsersByCompany } from "../../../hooks/useUsers";
import {
  useDelegateAllMaterials,
  useUpdateMaterial,
} from "../../../hooks/useMaterials";
import { useUpdateMaterialOffer } from "../../../hooks/useMaterialsOffers";

type DelegateProps = {
  user: UserResponse;
  material?: MaterialResponse;
  materialOffer?: MaterialOfferResponse;
  onCloseDelegate?: () => void;
  onOpenNotification: (message: string) => void;
  isDelegateAll?: boolean;
  isDelegateMaterialOffer?: boolean;
};

const Delegate = ({
  user,
  material,
  materialOffer,
  onCloseDelegate,
  onOpenNotification,
  isDelegateAll = false,
  isDelegateMaterialOffer = false,
}: DelegateProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selected, setSelected] = useState<any>();

  const usersByCompany = useUsersByCompany(user?.id);

  const { mutate: mutateMaterial, isPending: isDelegatingMaterial } =
    useUpdateMaterial(
      () => {
        onOpenNotification(
          `${t("delegate.success")} ${selected?.value?.fullName}!`
        );
        return navigate(`/materials`);
      },
      () => onOpenNotification(t("newMaterialForm.error"))
    );

  const { mutate: mutateMaterialOffer, isPending: isDelegatingMaterialOffer } =
    useUpdateMaterialOffer(
      () => {
        onOpenNotification(
          `${t("delegate.successMaterialOffer")} ${selected?.value?.fullName}!`
        );
        return navigate(`/material/offers`);
      },
      () => onOpenNotification(t("newMaterialForm.error"))
    );

  const { mutate: mutateAllMaterials, isPending: isDelegatingAll } =
    useDelegateAllMaterials(
      () => {
        onOpenNotification(
          `${t("delegate.all")} ${selected?.value?.fullName}!`
        );
        return navigate(`/materials`);
      },
      () => onOpenNotification(t("newMaterialForm.error"))
    );

  const onDelegateMaterial = () => {
    if (!selected?.value?.id) return;
    if (isDelegateMaterialOffer && materialOffer?.id) {
      mutateMaterialOffer({
        materialOfferId: materialOffer.id,
        body: {
          ...materialOffer,
          user: { id: selected?.value?.id },
        },
      });
    } else {
      if (isDelegateAll) {
        mutateAllMaterials(selected?.value?.id);
      } else {
        if (material)
          mutateMaterial({
            materialId: material?.id,
            body: {
              ...material,
              user,
              owner: { id: selected?.value?.id },
            },
          });
      }
    }
  };

  if (isDelegatingMaterialOffer || isDelegatingMaterial || isDelegatingAll)
    return <Loader isFullScreen />;

  return (
    <Fragment>
      <div>
        <PickList
          selected={selected}
          onChange={setSelected}
          list={usersByCompany.data}
          title={t("delegate.delegateTo")}
          placeholder={t("delegate.select")}
        />
      </div>
      <div className="pt-4 flex items-center gap-6">
        <button
          className="py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light uppercase"
          onClick={onDelegateMaterial}
        >
          {t("delegate.delegate")}
        </button>
        <button
          onClick={onCloseDelegate}
          className="py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light uppercase mr-3"
        >
          {t("cta.cancel")}
        </button>
      </div>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Delegate);

import i18next from "../../i18n";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { NO_SPEC } from "../../utils";
import { OfferResponse } from "../../types";

const IsBuyerOffer = ({ offer }: { offer: OfferResponse }) => {
  return (
    <div className="relative h-full">
      {offer?.selected && (
        <div className="mb-2">
          <p className="py-2 px-4 text-xs leading-5 font-semibold bg-green-700 text-white uppercase rounded">
            {i18next.t("offer.success")}
          </p>
        </div>
      )}
      <div className="shadow p-4 rounded border border-spectum">
        <h4 className="text-xs font-bold text-gray-600 uppercase">
          {i18next.t("offer.from")}
        </h4>
        <h1 className="text-sm font-bold text-gray-900">
          {offer?.user?.company?.name} -{" "}
          <span className="text-gray-500">{offer?.user?.company?.taxId}</span>
        </h1>
        <div className="bg-white overflow-hidden">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-3">
              <dt className="uppercase text-xs font-medium text-gray-500 pb-1">
                {i18next.t("offer.table.price")}
              </dt>
              <dd className="text-xs text-gray-900">
                {offer?.price} {offer?.order?.currency} /{" "}
                {offer?.order?.measurementUnit}{" "}
              </dd>
            </div>
          </dl>
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-3">
              <dt className="uppercase text-xs font-medium text-gray-500 pb-1">
                {i18next.t("offer.table.quantity")}
              </dt>
              <dd className="text-xs text-gray-900">
                {offer?.order?.quantity} {offer?.order?.measurementUnit}
              </dd>
            </div>
          </dl>
          {offer?.fileURL && offer?.fileURL !== NO_SPEC && (
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-3">
                <dt className="uppercase text-xs font-medium text-gray-500 pb-1">
                  {i18next.t("offer.table.technical")}
                </dt>
                <dd className="text-xs text-gray-900">
                  <a
                    href={offer?.fileURL}
                    target="_blank"
                    rel="noreferrer"
                    className="inline-flex items-center p-1 border border-transparent shadow-sm text-xs leading-4 font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
                  >
                    <ArrowDownTrayIcon
                      className="-ml-0.5 mr-2 h-4 w-4"
                      aria-hidden="true"
                    />
                    {i18next.t("cta.download")}
                  </a>
                </dd>
              </div>
            </dl>
          )}
          {offer?.comment && (
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-3">
                <dt className="uppercase text-xs font-medium text-gray-500 pb-1">
                  {i18next.t("offer.comments")}
                </dt>
                <dd className="text-xs text-gray-900">{offer?.comment}</dd>
              </div>
            </dl>
          )}
          {offer?.selected && (
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-3">
                <dt className="uppercase text-xs font-medium text-gray-500 pb-1">
                  {i18next.t("offer.table.quantitySelected")}
                </dt>
                <dd className="text-xs text-gray-900">
                  {offer?.price} {offer?.order?.currency} /{" "}
                  {offer?.order?.measurementUnit}
                  <br /> {offer?.quantity} {offer?.order?.measurementUnit}
                </dd>
              </div>
            </dl>
          )}
        </div>
      </div>
    </div>
  );
};

export default IsBuyerOffer;

import { Fragment } from "react";
import { connect } from "react-redux";
import {
  ArrowDownTrayIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";
import i18next from "../../../i18n";
import { AlertBanner, InfoItem, OrderFileList } from "../../../components";
import {
  BIDDING_TYPES,
  formatDate,
  labelType,
  mapStateToProps,
} from "../../../utils";
import { MaterialResponse, ORDER_TYPES, OrderState } from "../../../types";

const thInverse = [
  { id: 1, name: i18next.t("tenders.tenderSummary.code") },
  { id: 2, name: i18next.t("tenders.tenderSummary.material") },
  { id: 3, name: i18next.t("tenders.tenderSummary.volume") },
  { id: 4, name: i18next.t("tenders.tenderSummary.initialPrice") },
  { id: 5, name: i18next.t("tenders.tenderSummary.percentageDecrement") },
  { id: 6, name: i18next.t("tenders.tenderSummary.tenderPeriod") },
];

const thNormal = [
  { id: 1, name: i18next.t("tenders.tenderSummary.code") },
  { id: 2, name: i18next.t("tenders.tenderSummary.material") },
  { id: 3, name: i18next.t("tenders.tenderSummary.volume") },
  { id: 4, name: i18next.t("tenders.tenderSummary.tenderPeriod") },
];

type MultiplePurchaseRequestSummaryDetailProps = {
  type: ORDER_TYPES;
  isBid?: boolean;
  multiplePurchaseRequest: any;
  specificationName: string;
  isRequester: boolean;
  setOpenSlide: (val: boolean) => void;
  setMaterialToShow: (material: MaterialResponse) => void;
};

const MultiplePurchaseRequestSummaryDetail = ({
  type,
  isBid,
  multiplePurchaseRequest,
  specificationName,
  isRequester,
  setOpenSlide,
  setMaterialToShow,
}: MultiplePurchaseRequestSummaryDetailProps) => {
  const data = multiplePurchaseRequest.orders[0];
  let tableTh = thNormal;

  if (type === ORDER_TYPES.INVERSE_JOINT || type === ORDER_TYPES.INVERSE) {
    tableTh = thInverse;
  }

  return (
    <div className="mt-4">
      <div className="sm:flex sm:items-center border-b pb-4 mb-4">
        <div>
          {!isBid && (
            <h1 className="text-xl font-semibold text-gray-900 mb-2">
              {i18next.t("tenders.summary")}
            </h1>
          )}
          {type && (
            <InfoItem
              name={i18next.t("tenders.form.tenderType")}
              description={labelType(type)}
            />
          )}
          {isRequester ? (
            <InfoItem
              name={i18next.t("tenders.tenderSummary.responsible")}
              description={
                multiplePurchaseRequest?.purchaseRequest?.order?.user?.fullName
              }
            />
          ) : (
            <InfoItem
              name={i18next.t("userTypes.requester")}
              description={
                multiplePurchaseRequest?.purchaseRequest?.user?.fullName
              }
            />
          )}
          {data?.startDate && (
            <InfoItem
              name={i18next.t("tenders.tenderSummary.initialDate")}
              description={formatDate(data.startDate)}
            />
          )}
          {data?.finishDate && (
            <InfoItem
              name={i18next.t("tenders.tenderSummary.endDate")}
              description={formatDate(data.finishDate)}
            />
          )}
          {data?.startDateValue && (
            <InfoItem
              name={i18next.t("tenders.tenderSummary.initialDate")}
              description={formatDate(data.startDateValue)}
            />
          )}
          {data?.endDateValue && (
            <InfoItem
              name={i18next.t("tenders.tenderSummary.endDate")}
              description={formatDate(data.endDateValue)}
            />
          )}
          {data?.paymentMethod && (
            <InfoItem
              name={i18next.t("tenders.tenderSummary.paymentMethod")}
              description={data?.paymentMethod}
            />
          )}
          {data?.paymentTerm && (
            <InfoItem
              name={i18next.t("tenders.tenderSummary.paymentTerm")}
              description={
                data?.paymentTerm === 1
                  ? ` ${i18next.t("opportunities.table.day")}`
                  : ` ${i18next.t("opportunities.table.days")}`
              }
            />
          )}
          {data?.state !== OrderState.REJECTED && data?.comment && (
            <InfoItem
              name={i18next.t("tenders.tenderSummary.comments")}
              description={data?.comment}
            />
          )}

          {specificationName && (
            <div className="bg-white overflow-hidden">
              <div className="py-2 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-2 sm:grid sm:grid-cols-2 sm:gap-4">
                    <dt className="uppercase text-xxs font-medium text-gray-500">
                      {i18next.t("newMaterialForm.plication")}
                    </dt>
                    <dd className="mt-1 text-xxs text-gray-900 sm:mt-0 sm:col-span-1">
                      <a
                        href={specificationName}
                        target="_blank"
                        rel="noreferrer"
                        className="inline-flex items-center p-1 border border-transparent shadow-sm text-xxs leading-4 font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
                      >
                        <ArrowDownTrayIcon
                          className="-ml-0.5 mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                        {i18next.t("cta.plication")}
                      </a>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          )}

          {data?.files?.length > 0 && <OrderFileList order={data} />}
        </div>
      </div>
      {data?.state === OrderState.REJECTED && (
        <AlertBanner
          title={"purchaseRequests.rejected"}
          comments={multiplePurchaseRequest?.purchaseRequest?.comments}
        />
      )}
      <div className="-mx-4 flex flex-col sm:-mx-6 md:mx-0">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              {tableTh.map((t) => (
                <th
                  key={t.id}
                  scope="col"
                  className="uppercase py-3.5 pl-4 pr-3 text-left text-xxs font-semibold text-gray-900 sm:pl-6 md:pl-0"
                >
                  {t.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {multiplePurchaseRequest &&
              multiplePurchaseRequest?.orders?.map((order: any) => (
                <tr key={order.id} className="border-b border-gray-200">
                  <td className="py-4 text-xxs">
                    <div className="font-medium text-gray-900 uppercase">
                      {isBid ? order?.material?.ean : order.ean}
                    </div>
                  </td>
                  <td className="py-4 text-xxs">
                    <div className="font-medium text-gray-900 uppercase flex items-center gap-3">
                      {isBid
                        ? order?.material?.globalMaterialName
                        : order.globalMaterialName}
                      <button
                        onClick={() => {
                          setMaterialToShow(
                            order?.material as MaterialResponse
                          );
                          setOpenSlide(true);
                        }}
                      >
                        <InformationCircleIcon className="h-5 w-5 text-spectum" />
                      </button>
                    </div>
                  </td>
                  <td className="py-4 text-xxs text-gray-500">
                    {order.quantity} {order.measurementUnit}
                  </td>
                  {(type === BIDDING_TYPES.INVERSE ||
                    type === BIDDING_TYPES.INVERSE_JOINT) && (
                    <Fragment>
                      <td className="py-4 text-xxs text-gray-500">
                        {order.initialPrice} {order.coin}
                      </td>
                      <td className="py-4 text-xxs text-gray-500">
                        {order.minimumDecrement} %
                      </td>
                    </Fragment>
                  )}
                  <td className="py-4 text-xxs text-gray-500">
                    {order.startSupplyDate === order.endSupplyDate ? (
                      formatDate(order.startSupplyDate)
                    ) : (
                      <>
                        {formatDate(order.startSupplyDate)} -{" "}
                        {formatDate(order.endSupplyDate)}
                      </>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(MultiplePurchaseRequestSummaryDetail);

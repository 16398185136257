import { Link } from "react-router-dom";
import { Pill } from "../..";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import { formatDate } from "../../../utils";
import { OrderResponse } from "../../../types";

const BidTableRow = ({ order }: { order: OrderResponse }) => {
  return (
    <tr key={order.id}>
      <td className="px-2 py-2 whitespace-nowrap text-xxs text-gray-900 uppercase font-bold">
        <Link to={`/order/${order.id}`}>
          {order?.internalCode ? order?.internalCode : " - "}
        </Link>
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-xxs text-gray-900 uppercase font-bold">
        <Link to={`/order/${order.id}`}>{order?.name}</Link>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xxs text-gray-500">
          {order.quantity} {order.measurementUnit}
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xxs text-gray-500">
          {order.startDate && formatDate(order.startDate)}
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xxs text-gray-500">
          {order.finishDate && formatDate(order.finishDate)}
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <Pill order={order} />
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-xxs text-gray-500">
        <Link
          to={`/order/${order.id}`}
          className="text-indigo-600 hover:text-indigo-900"
        >
          <ChevronDoubleRightIcon className="h-6 w-6" />
        </Link>
      </td>
    </tr>
  );
};

export default BidTableRow;

import { connect } from "react-redux";
import { mapStateToProps, formatDate, BIDDING_LABELS } from "../../../utils";
import { PurchaseRequestOrderResponse } from "../../../types";

type PurchaseRequestsOrdersTableRowProps = {
  order: PurchaseRequestOrderResponse;
};

const PurchaseRequestsOrdersTableRow = ({
  order,
}: PurchaseRequestsOrdersTableRowProps) => {
  return (
    <tr key={order.id}>
      <td className="px-2 py-2 whitespace-nowrap text-xxs text-gray-900 uppercase font-semibold">
        {order.name}
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xxs text-gray-500">
          {formatDate(order.startSupplyDate)}{" "}
          {order.endSupplyDate !== order.startSupplyDate &&
            `- ${formatDate(order.endSupplyDate)}`}
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xxs text-gray-500">
          {formatDate(order.createdAt)}
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <span className="px-2 inline-flex text-xxs leading-5 font-semibold rounded bg-yellow-100 text-yellow-800">
          {BIDDING_LABELS.PENDING}
        </span>
      </td>
    </tr>
  );
};

export default connect(mapStateToProps)(PurchaseRequestsOrdersTableRow);

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ClipLoader } from "react-spinners";
import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowDownTrayIcon } from "@heroicons/react/20/solid";
import { Button, Loader } from "../../components";
import {
  onGetPurchaseRequestFile,
  useCreatePurchaseRequestOrder,
} from "../../hooks/usePurchaseRequests";
import i18next from "../../i18n";
import { DELIVERY_TYPE, MaterialType, UserResponse } from "../../types";
import { useFileInputUploader } from "../../hooks";
import {
  mapDispatchToProps,
  mapStateToProps,
  purchaseRequestOrderValidationSchema,
} from "../../utils";

type FormValues = {
  name: string;
  spot: DELIVERY_TYPE;
  startSupplyDate: string;
  endSupplyDate: string;
  file: string;
};

const PurchaseRequestUploader = ({
  user,
  onOpenNotification,
}: {
  user: UserResponse;
  onOpenNotification: (message: string) => void;
}) => {
  const navigate = useNavigate();
  const [pnType, setPnType] = useState<MaterialType | string>("");
  const [fileName, setFileName] = useState<string | null>(null);
  const [fileError, setFileError] = useState<string>("");
  const [purchaseRequestOrderFile, setPurchaseRequestOrderFile] = useState<
    string | null
  >(null);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(purchaseRequestOrderValidationSchema),
    defaultValues: {
      name: "",
      spot: DELIVERY_TYPE.SPOT,
      startSupplyDate: "",
      endSupplyDate: "",
      file: "",
    },
  });

  const {
    uploadedSpecificationFileUrl,
    handleSpecificationUpload,
    isLoadingUpload,
  } = useFileInputUploader();

  const {
    mutate: createPurchaseRequestOrder,
    isPending: isCreatingPurchaseRequestOrder,
  } = useCreatePurchaseRequestOrder(() => {
    onOpenNotification(i18next.t("purchaseRequestOrders.success"));
    reset();
    sessionStorage.clear();
    setPurchaseRequestOrderFile(null);
    setPnType("");
    setFileName(null);
    navigate("/");
  });

  const spot = watch("spot");
  const startSupplyDate = watch("startSupplyDate");

  const handleSpecification = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0]; // Get the first file
    setFileError("");
    if (file) {
      // Check file type or extension
      const validExtensions = [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ]; // Excel MIME types
      if (!validExtensions.includes(file.type)) {
        setFileError("validations.fileTypeExcel");
        return;
      }

      // If valid, proceed with the upload
      handleSpecificationUpload([file]);
      setFileName(file.name);
      sessionStorage.setItem("fileName", file.name);
    }
  };

  useEffect(() => {
    const file = sessionStorage.getItem("purchaseRequestOrderFile");
    const name = sessionStorage.getItem("fileName");
    const sessionPnType = sessionStorage.getItem("pnType");
    file && setPurchaseRequestOrderFile(file);
    name && setFileName(name);
    sessionPnType && setPnType(sessionPnType as MaterialType);
    if (uploadedSpecificationFileUrl) {
      setPurchaseRequestOrderFile(uploadedSpecificationFileUrl);
      setValue("file", uploadedSpecificationFileUrl);
      sessionStorage.setItem(
        "purchaseRequestOrderFile",
        uploadedSpecificationFileUrl
      );
      sessionStorage.setItem("pnType", pnType || "");
    }
  }, [uploadedSpecificationFileUrl]);

  const onSubmit = (data: FormValues) => {
    createPurchaseRequestOrder({
      ...data,
      user: { id: user?.id },
      file: uploadedSpecificationFileUrl
        ? uploadedSpecificationFileUrl
        : purchaseRequestOrderFile,
      spot: data.spot === DELIVERY_TYPE.SPOT ? true : false,
      endSupplyDate:
        data.spot === DELIVERY_TYPE.SPOT
          ? data.startSupplyDate
          : data.endSupplyDate,
    });
  };

  return (
    <div className="px-4 mt-8">
      {isCreatingPurchaseRequestOrder ? (
        <Loader isFullScreen />
      ) : (
        <>
          <div className="md:flex items-center justify-between mb-6">
            <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate uppercase">
              {i18next.t("purchaseRequestOrders.title")}
            </h2>
          </div>
          <div className="mb-4">
            <p className="text-gray-600 font-semibold">
              {i18next.t("purchaseRequestOrders.description")}
              <br />
              <br />
              {i18next.t("purchaseRequestOrders.description2")}
            </p>
          </div>
          <div className="my-2 border rounded-md p-4 shadow">
            <div className="flex gap-3 items-center">
              <select
                className="border-gray-300 border cursor-pointer uppercase font-semibold text-xs rounded m-0"
                onChange={(e) => setPnType(e.target.value as MaterialType)}
                value={pnType}
              >
                <option value="">{i18next.t("validations.selectType")}</option>
                <option value={MaterialType.PI}>
                  {i18next.t("titles.productive")}
                </option>
                <option value={MaterialType.NPI}>
                  {i18next.t("titles.nonProductive")}
                </option>
              </select>
              {pnType && (
                <Button
                  variant="add"
                  onClick={() =>
                    onGetPurchaseRequestFile(pnType as MaterialType)
                  }
                >
                  <ArrowDownTrayIcon
                    aria-hidden="true"
                    className="-ml-0.5 mr-1.5 size-5"
                  />
                  {i18next.t("purchaseRequestOrders.downloadFile")}
                </Button>
              )}
            </div>
            <div className="my-2">
              {pnType && (
                <>
                  <div className="mt-4 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded">
                    <div className="space-y-1 text-center">
                      <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {fileName && <span>{fileName}</span>}

                      <div className="text-xs text-gray-600">
                        <label
                          htmlFor="file"
                          className="uppercase relative cursor-pointer bg-white rounded font-medium text-spectum hover:text-spectum-light focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-spectum-light"
                        >
                          {isLoadingUpload ? (
                            <div className="flex w-full items-center justify-center">
                              <ClipLoader loading size={30} />
                            </div>
                          ) : (
                            <span>{i18next.t("newMaterialForm.upload")}</span>
                          )}
                          <input
                            {...register("file")}
                            id="file"
                            name="file"
                            type="file"
                            className="sr-only"
                            onChange={(e) => handleSpecification(e)}
                          />
                          {errors?.file && (
                            <span className="text-xs text-red-500">
                              {errors?.file?.message}
                            </span>
                          )}
                        </label>
                      </div>

                      <p className="text-xs text-gray-500">XLS / XLSX</p>
                    </div>
                  </div>
                  {fileError && (
                    <span className="text-xs text-red-500">
                      {i18next.t(fileError)}
                    </span>
                  )}
                </>
              )}
              {purchaseRequestOrderFile && (
                <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                  <div className="mt-4">
                    <div className="overflow-hidden">
                      <div className="grid grid-cols-2 gap-4 bg-white">
                        <div className="col-span-2">
                          <label
                            htmlFor="name"
                            className="uppercase block text-xs font-medium text-gray-700"
                          >
                            {i18next.t("purchaseRequests.name")}
                            <span className="text-sm text-red-600"> *</span>
                          </label>
                          <input
                            type="text"
                            id="name"
                            {...register("name", { required: true })}
                            className="mt-1 focus:ring-spectum-light focus:border-spectum-light block shadow-sm sm:text-xs border-gray-300 rounded w-full"
                          />
                          {errors?.name && (
                            <span className="text-xs text-red-500">
                              {errors?.name?.message}
                            </span>
                          )}
                        </div>
                        <div className="col-span-2 grid grid-cols-3">
                          <div className="col-span-1">
                            <label
                              htmlFor="spot"
                              className="uppercase block text-xs font-medium text-gray-700"
                            >
                              {i18next.t("tenders.form.tenderPeriod")}
                              <span className="text-sm text-red-600"> *</span>
                            </label>
                            <div className="flex items-center gap-4 mt-2">
                              <div className="flex items-center gap-2">
                                <input
                                  type="radio"
                                  id="spot"
                                  value={DELIVERY_TYPE.SPOT}
                                  {...register("spot")}
                                  className="cursor-pointer"
                                />
                                <label
                                  className="uppercase text-xs text-gray-700 font-medium"
                                  htmlFor="spot"
                                >
                                  {i18next.t("tenders.form.spot")}
                                </label>
                              </div>
                              <div className="flex items-center gap-2">
                                <input
                                  type="radio"
                                  id="period"
                                  value={DELIVERY_TYPE.PERIOD}
                                  {...register("spot")}
                                  className="cursor-pointer"
                                />
                                <label
                                  className="uppercase text-xs text-gray-700 font-medium"
                                  htmlFor="period"
                                >
                                  {i18next.t("tenders.form.period")}
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-span-2 flex items-center gap-3 relative w-full">
                            {spot === DELIVERY_TYPE.SPOT ? (
                              <div className="w-full">
                                <label
                                  htmlFor="startSupplyDate"
                                  className="uppercase block text-xs font-medium text-gray-700"
                                >
                                  {i18next.t("tenders.form.date")}
                                  <span className="text-sm text-red-600">
                                    {" "}
                                    *
                                  </span>
                                </label>
                                <input
                                  type="date"
                                  id="startSupplyDate"
                                  {...register("startSupplyDate", {
                                    required: true,
                                  })}
                                  className="mt-1 focus:ring-spectum-light focus:border-spectum-light block w-full shadow-sm sm:text-xs border-gray-300 rounded"
                                />
                                {errors?.startSupplyDate && (
                                  <span className="text-xs text-red-500">
                                    {errors?.startSupplyDate?.message}
                                  </span>
                                )}
                              </div>
                            ) : (
                              <>
                                <div className="w-full">
                                  <label
                                    htmlFor="startSupplyDate"
                                    className="uppercase block text-xs font-medium text-gray-700"
                                  >
                                    {i18next.t("tenders.form.from")}
                                    <span className="text-sm text-red-600">
                                      {" "}
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="date"
                                    id="startSupplyDate"
                                    {...register("startSupplyDate", {
                                      required: true,
                                    })}
                                    className="mt-1 focus:ring-spectum-light focus:border-spectum-light block w-full shadow-sm sm:text-xs border-gray-300 rounded"
                                  />
                                  {errors?.startSupplyDate && (
                                    <span className="text-xs text-red-500">
                                      {errors?.startSupplyDate?.message}
                                    </span>
                                  )}
                                </div>
                                <div className="w-full">
                                  <label
                                    htmlFor="endSupplyDate"
                                    className="uppercase block text-xs font-medium text-gray-700"
                                  >
                                    {i18next.t("tenders.form.to")}
                                    <span className="text-sm text-red-600">
                                      {" "}
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="date"
                                    id="endSupplyDate"
                                    min={startSupplyDate}
                                    {...register("endSupplyDate", {
                                      required: true,
                                    })}
                                    disabled={!startSupplyDate}
                                    className="mt-1 focus:ring-spectum-light focus:border-spectum-light block w-full shadow-sm sm:text-xs border-gray-300 rounded"
                                  />
                                  {errors?.endSupplyDate && (
                                    <span className="text-xs text-red-500">
                                      {errors?.endSupplyDate?.message}
                                    </span>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex items-end justify-end mt-6">
                    <Button type="submit">
                      {i18next.t("purchaseRequestOrders.title")}
                    </Button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseRequestUploader);
